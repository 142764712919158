import { gsap } from 'gsap';
import { Globals } from '../data/Globals';

export class CenterCTA {
	private _element: HTMLDivElement;

	private _headline1: HTMLDivElement;
	private _headline2: HTMLDivElement;
	private _subHeadLine: HTMLDivElement;

	private _giveAHandButton: HTMLDivElement;
	private _browseDataSetButton: HTMLDivElement;

	private _buttonInvertedOuter: HTMLDivElement;

	constructor(element: HTMLDivElement) {
		this._element = element;

		this._headline1 = this._element.querySelector('.headline1');
		this._headline2 = this._element.querySelector('.headline2');
		this._subHeadLine = this._element.querySelector('.subHeadline');

		this._giveAHandButton = this._element.querySelector('.button1');
		this._giveAHandButton.addEventListener('click', this.onGiveAHandClick);

		this._browseDataSetButton = this._element.querySelector('.button2');
		this._browseDataSetButton.addEventListener('click', this.onBrowseDataSetClick);

		this._buttonInvertedOuter = document.body.querySelector('.buttonInvertedOuter');
	}

	private onGiveAHandClick = event => {
		Globals.VIEW_MANAGER.setPath('give-a-hand');
	};

	private onBrowseDataSetClick = event => {
		console.log('Not yet done');
		console.log(Globals.MAIN_SCROLL_TRIGGER);

		function labelToScroll(timeline, label) {
			let st = Globals.MAIN_SCROLL_TRIGGER;
			console.log(timeline.labels);
			var getEndPosition = st.start + (st.end - st.start) * (timeline.labels[label] / timeline.duration());
			console.log(getEndPosition);
			return getEndPosition;
		}
		var getScrollPos = labelToScroll(Globals.MAIN_SCROLL_TRIGGER.animation, 'gotoDataset');
		gsap.to(window, { scrollTo: getScrollPos, duration: 1, ease: 'power1.in' });
	};

	public revealCenterCTA = () => {};

	public browseDatasetScroll = () => {};

	private onRollOver = event => {};

	private onRollOut = event => {};

	get element(): HTMLDivElement {
		return this._element;
	}

	get giveAHandButton() {
		return this._giveAHandButton;
	}

	get headline1() {
		return this._headline1;
	}

	get headline2() {
		return this._headline2;
	}
	get subHeadline() {
		return this._subHeadLine;
	}
	get browseDataSetButton() {
		return this._browseDataSetButton;
	}

	get buttonInverted() {
		return this._buttonInvertedOuter;
	}
}
