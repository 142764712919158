import { SphereGeometry, Mesh, MeshBasicMaterial, PerspectiveCamera, Plane, Raycaster, Scene, Vector2, Vector3, Group } from 'three';
import { gsap } from 'gsap';
import { MeshLine, MeshLineMaterial } from 'threejs-meshline';
import * as THREE from 'three';
import { WindowManager } from './WindowManager';

interface IBounds {
	width: number;
	height: number;
	minY: number;
}

export class RayBounds {
	private readonly _camera: PerspectiveCamera;
	private _raycaster: Raycaster = new Raycaster();
	private _mouse: Vector2 = new Vector2();
	private _planeNormal: Vector3 = new Vector3();
	private _plane: Plane = new Plane();
	private _scene: Scene;
	// private _lineMaterial: MeshLineMaterial;
	// private _lineMesh: THREE.Mesh;
	private _container: Group = new Group();

	private _bounds: IBounds = { width: 0, height: 0, minY: 0 };

	constructor(camera: PerspectiveCamera, scene: Scene) {
		this._camera = camera;
		this._scene = scene;

		this._scene.add(this._container);

		// this._lineMaterial = new MeshLineMaterial({ color: 0xff4400, lineWidth: 0.0009 * 0.01, transparent: true, opacity: 0.75, dashArray: 0.02, dashRatio: 0.3 }); // ,
		// gsap.to(this._lineMaterial, { duration: 20, dashOffset: 1, repeat: -1, ease: 'none' });
		//
		// gsap.to(this._lineMaterial, { duration: 0.3, opacity: 1 });

		// window.addEventListener('click', this.onClick);
	}

	// private onClick = (e) => {
	// 	let point = this.getPoint(e.clientX, e.clientY);
	//
	// 	let cube = new Mesh(new SphereGeometry(0.001, 100, 100), new MeshBasicMaterial({color: 0xFF0000 * Math.random()}));
	// 	cube.scale.set(0.04, 0.04, 0.04);
	// 	cube.position.copy(point);
	// 	this._scene.add(cube);
	// }

	private getPoint = (x: number, y: number) => {
		let point: Vector3 = new Vector3(0, 0, 0);
		this._mouse.x = (x / window.innerWidth) * 2 - 1;
		this._mouse.y = -(y / window.innerHeight) * 2 + 1;
		this._planeNormal.copy(this._camera.position).normalize();
		this._plane.setFromNormalAndCoplanarPoint(this._planeNormal, this._scene.position);
		this._raycaster.setFromCamera(this._mouse, this._camera);
		this._raycaster.ray.intersectPlane(this._plane, point);
		return point;
	};

	// private drawLines = (points: Array<THREE.Vector3>) => {
	// 	points.push(points[0].clone());
	//
	// 	if (this._lineMesh) {
	// 		this._container.remove(this._lineMesh);
	// 		this._lineMesh = null;
	// 	}
	// 	let geometry = new THREE.Geometry();
	//
	// 	let l = points.length;
	// 	for (let i = 0; i < l; i++) {
	// 		let point = points[i].clone();
	// 		// point.z = -0.0001;
	// 		geometry.vertices.push(point);
	// 	}
	//
	// 	let line = new MeshLine();
	// 	line.setGeometry(geometry);
	//
	// 	this._lineMesh = new THREE.Mesh(line.geometry, this._lineMaterial);
	// 	this._container.add(this._lineMesh);
	// };

	public update = (height: number, minY: number) => {
		let points = [
			this.getPoint(40, minY), // Top Left
			this.getPoint(WindowManager.width - 40, minY), // Top Right
			this.getPoint(WindowManager.width - 40, height), // Bottom Right
			this.getPoint(40, height) // Bottom Left
		];

		// this.drawLines(points);

		this._bounds.width = points[1].x - points[0].x;
		this._bounds.height = Math.abs(points[2].y - points[1].y);
		this._bounds.minY = points[0].y;
	};

	get bounds(): IBounds {
		return this._bounds;
	}
}
