import * as THREE from 'three';
import { AnimationAction, MeshBasicMaterial, MeshLambertMaterial, MeshPhongMaterial, MeshToonMaterial, Object3D, TextureLoader } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { gsap } from 'gsap';
import { CinematicCamera } from 'three/examples/jsm/cameras/CinematicCamera';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Globals } from '../data/Globals';
import Stats from 'three/examples/jsm/libs/stats.module';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { HandObjectPool } from './HandObjectPool';
import { HandJoints } from '../three/ui/HandJoints';
import { Gesture, STATUS_APPROVED_KEYPOINTS_MANUALLY_ADJUSTED } from '../data/Gesture';

export class HandScene {
	private _camera;
	private _scene: THREE.Scene;
	private _renderer: THREE.WebGLRenderer;
	private _clock: THREE.Clock;
	private _mixer: THREE.AnimationMixer;
	private _stats: Stats;

	private _animateResize = { width: 0, height: 0 };

	private _animationMixes: Array<THREE.AnimationAction> = [];
	private _currentAnimation: AnimationAction;

	private _handLoadedCallback: Function;

	private _currentWordInSentenceCount: number = 0;
	private _sentenceArray = [];

	private _pointer = { x: 0, y: 0 };

	private _hand;

	private _DEBUG = false;

	private _movedToLeft: boolean = false;

	private _previousLetter: number;
	private _container: HTMLDivElement;

	private _currentBGColor = { color: 0x000000 };

	private _particleGroup: THREE.Group;
	private _particleGroupOuter: THREE.Group;

	private _handImageGroupOuter: THREE.Group = new THREE.Group();
	private _handImageGroup: THREE.Group = new THREE.Group();

	private _particlesWhiteMesh;
	private _particlesRedMesh;
	private _particlesBlueMesh;

	private _allPlanes;

	private _allVertices = [];
	private _allVerticePositions = [];
	private _allPointGroups = [];

	private _raycaster = new THREE.Raycaster();
	private _INTERSECTED;

	private _geometryPointsWhite;

	private _planes = [];
	private _cameraMaxZ = 500;

	private _centerCTA;
	private _buttonInvertedOuter;

	private _scrollTrigger: ScrollTrigger;

	private _handObjectPool: HandObjectPool;

	private _storeScrollPercentageStored: number = 0;

	private _datasetAdded: boolean = false;

	private _handViewOpen: boolean = false;

	private _handJoints: HandJoints;

	private _innerWidth = 0;
	private _innerHeight = 0;

	private _onMouseMoveDivider = { count: 1 };

	private _handIDNumber = { count: 0 };

	private _heroVideo: HTMLVideoElement;

	get DEBUG(): boolean {
		return this._DEBUG;
	}

	set DEBUG(value: boolean) {
		this._DEBUG = value;
	}

	constructor(onHandLoaded: Function) {
		//window.THREE = THREE;
		//console.log('contructor');
		this._clock = new THREE.Clock();
		this.init();
		this.animate();
	}

	private init = () => {
		// Register DOM Elements
		this._centerCTA = document.body.querySelector('.centerCTA');
		this._buttonInvertedOuter = document.body.querySelector('.buttonInvertedOuter');

		// Build scene
		this._camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 20000);
		//this._camera.setLens( 5 );
		this._camera.position.set(0, 0, 0);

		this._scene = new THREE.Scene();
		this._scene.background = new THREE.Color(this._currentBGColor.color);

		this._scene.fog = new THREE.Fog(this._currentBGColor.color, 0, 200);

		this._particleGroupOuter = new THREE.Group();
		this._scene.add(this._particleGroupOuter);

		this._particleGroup = new THREE.Group();
		this._particleGroupOuter.add(this._particleGroup);

		this._renderer = new THREE.WebGLRenderer({ antialias: true });
		//	this._renderer.outputEncoding = THREE.sRGBEncoding;
		this._renderer.setPixelRatio(window.devicePixelRatio);
		this._renderer.setSize(window.innerWidth, window.innerHeight);
		this._renderer.shadowMap.enabled = false;
		this._renderer.domElement.style.position = 'fixed';
		var useContainer = document.getElementById('HandScene');
		useContainer.appendChild(this._renderer.domElement);

		gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
		//og(ScrollTrigger);

		this.addHandJoints(this._camera, this._scene, this._renderer);

		this._handImageGroupOuter.position.z = -1000;

		this._scene.add(this._handImageGroupOuter);

		this._handObjectPool = new HandObjectPool(this._handImageGroup);
		this._handImageGroup.add(this._handObjectPool.mainGroup);
		this._handImageGroupOuter.add(this._handImageGroup);

		window.addEventListener('resize', this.onWindowResize);

		this.onWindowResize();

		// stats
		this._stats = Stats();
		if (Globals.DEBUG) {
			document.body.appendChild(this._stats.dom);
		}

		//Globals.MAIN_VIEW_CONTAINER.addEventListener('wheel', this.onMouseWheel, {passive: false});
	};

	private onMouseWheel = event => {
		if (Globals.HAND_SCENE_ACTIVE === true) {
		} else {
			//console.log('preventing')
			event.preventDefault();
			event.stopPropagation();
		}
	};

	public addHandJoints = (camera, scene, renderer) => {
		this._handJoints = new HandJoints(1, camera, renderer, document.body, this, 1000, 1000);
		Globals.HAND_JOINTS = this._handJoints;
		this._handJoints.position.z = 20;
		this._scene.add(this._handJoints);
		//	console.log(Globals.DATA_MANAGER.getAllData()[0].data);

		var getData = Globals.DATA_MANAGER.getAllData()[0].data;

		var pointData = [
			getData.key_point_0,
			getData.key_point_1,
			getData.key_point_2,
			getData.key_point_3,
			getData.key_point_4,
			getData.key_point_5,
			getData.key_point_6,
			getData.key_point_7,
			getData.key_point_8,
			getData.key_point_9,
			getData.key_point_10,
			getData.key_point_11,
			getData.key_point_12,
			getData.key_point_13,
			getData.key_point_14,
			getData.key_point_15,
			getData.key_point_16,
			getData.key_point_17,
			getData.key_point_18,
			getData.key_point_19,
			getData.key_point_20
		];

		this._handJoints.updatePoints(pointData, 200, 200);
	};

	private onGiveAHandClick = () => {
		Globals.VIEW_MANAGER.setPath('give-a-hand');
	};

	public animateBackgroundColor = newColor => {
		var setColor = new THREE.Color(newColor);
		//	console.log(setColor);
		gsap.to(this._scene.background, { duration: 1, r: setColor.r, g: setColor.g, b: setColor.b, ease: 'power1.out' });
		gsap.to(this._scene.fog.color, { duration: 1, r: setColor.r, g: setColor.g, b: setColor.b, ease: 'power1.out' });

		gsap.to(Globals.GIVE_A_HAND_BUTTON.element, { x: 0, ease: 'power1.out', duration: 1 });
		gsap.to(Globals.SUB_MENU.element, { x: 0, ease: 'power1.out', duration: 1 });
	};

	public switchToGiveAHand = () => {
		this._storeScrollPercentageStored = window.scrollY / Globals.SCROLL_HEIGHT;
		/*console.log('switchToGiveAHand()');
		console.log('this._storeScrollPercentageStored : ' + this._storeScrollPercentageStored);
		console.log('window.scrollY : ' + window.scrollY);
		console.log('Globals.MAIN_VIEW_CONTAINER.style.height : ' + Globals.MAIN_VIEW_CONTAINER.style.height);*/

		this._scrollTrigger.disable(false, false);
		// Fix me - implement something that remembers the scroll percentage before click
		Globals.MAIN_VIEW_CONTAINER.style.height = '100vh';
		gsap.to(this._scene.fog, { near: 1, far: 1, duration: 1, ease: 'power1.out' });
		gsap.to(this._camera, { near: 1, far: 1, duration: 1, ease: 'power1.out' });
		this.animateBackgroundColor(0x666a60);
		gsap.to(Globals.GIVE_A_HAND_BUTTON.element, { x: 200, ease: 'power1.in', duration: 1 });
		gsap.to(Globals.SUB_MENU.element, { x: -200 - 200, ease: 'power1.in', duration: 1 });
		gsap.to(Globals.MAIN_VIEW_CONTAINER, { opacity: 0, ease: 'power1.in', duration: 0.2 });

		Globals.HAND_SCENE_ACTIVE = false;
		// Reset pointerevent to default

		document.body.style.cursor = 'default';
	};

	public switchToMainScene = () => {
		Globals.HAND_SCENE_ACTIVE = true;

		Globals.MAIN_VIEW_CONTAINER.style.height = Globals.SCROLL_HEIGHT + 'px';
		//	console.log('switchToMainScene()');

		this.nextFrame();
		//requestAnimationFrame(this.nextFrame);
	};

	private nextFrame = () => {
		var scrollToY = this._storeScrollPercentageStored * Globals.SCROLL_HEIGHT;
		/*	console.log('Globals.MAIN_VIEW_CONTAINER.style.height : ' + Globals.MAIN_VIEW_CONTAINER.style.height);
			console.log('this._storeScrollPercentageStored : ' + this._storeScrollPercentageStored);
			console.log('scrollToY : ' + scrollToY);*/

		//window.scrollTo(0, scrollToY);

		//	this._scrollTrigger.refresh();

		this._scrollTrigger.enable();

		this._scrollTrigger.scroll(scrollToY);
		window.scrollTo(0, scrollToY);

		gsap.to(this._scene.fog, { far: 200, duration: 1, ease: 'power1.out' });
		gsap.to(this._camera, { near: 1, far: 500, duration: 1, ease: 'power1.out' });
		this.animateBackgroundColor(0x000000);
		gsap.to(Globals.MAIN_VIEW_CONTAINER, { opacity: 1, ease: 'power1.out', duration: 0.2, delay: 0.8 });
	};

	public loadHand = (loadHandCallback: Function, onProgressCallback: Function) => {
		this._handLoadedCallback = loadHandCallback;
		// model
		const loader = new GLTFLoader();

		// Load a glTF resource
		loader.load(
			// resource URL
			'assets/3d/rigget_V16.glb',
			// called when the resource is loaded
			this.onHandLoaded,
			// called while loading is progressing
			function(xhr) {
				var percentLoaded = (xhr.loaded / xhr.total) * 100;
				//console.log(percentLoaded + '% loaded');
				onProgressCallback(percentLoaded);
			},
			// called when loading has errors
			function(error) {
				//	console.log(error);
			}
		);
	};

	private updateVerticePosition = (count, positionData, currentPointGroup) => {
		//	console.log(count)
		if (count < 10) {
			//	console.log('positionData.position.z : ' + positionData.positions.z)
		}
		var positions = currentPointGroup.attributes.position;
		positions.setZ(count, positionData.positions.z);
		positions.needsUpdate = true;
	};

	public rotateBlocks = () => {
		const tl = gsap.timeline();
		for (var i = 0; i < this._allPlanes.length; i++) {
			var currentPlane = this._allPlanes[i];
			var getnewZPos = currentPlane.userData.newZPosition;
			tl.to(currentPlane.rotation, { delay: 1 + getnewZPos / 10, duration: 1.5, z: Math.PI / 1.8, x: 0.2, yoyo: true, ease: 'power1.out' }, 0);
		}
		return tl;
	};

	public mainScroll = () => {
		var masterTimeline = gsap.timeline();
		masterTimeline.add(this.animateHandParticlesIn(), 1);
		//	masterTimeline.add(this.introItemScroll(), -30);
		masterTimeline.addLabel('firstTitle', 10);

		var moveBack = 15;
		masterTimeline.add(this.moveHand(), 15 + moveBack);
		masterTimeline.add(this.distortHand(), 16 + moveBack);
		masterTimeline.add(this.centerCTA(), 20 + moveBack);

		masterTimeline.addLabel('gotoDataset', 80);

		//masterTimeline.add(this.rotateBlocks(), 17);

		Globals.HAND_SCENE_MASTER_TIMELINE = masterTimeline;

		masterTimeline.addLabel('endLabel', 300);

		return masterTimeline;
	};

	public animateHandParticlesIn = () => {
		const tl = gsap.timeline();

		var preloaderItem = document.body.querySelector('#Preloader');
		var introElements = document.body.querySelector('#IntroElements');
		var headline = preloaderItem.querySelector('.center');
		var title1 = introElements.querySelector('.title1');
		var title2 = introElements.querySelector('.title2');
		var title3 = introElements.querySelector('.title3');
		this._heroVideo = document.body.querySelector('#HeroVideo');

		var subtitles = document.body.querySelector('#subtitles');

		gsap.set([title1, title2, title3], { scale: 40, opacity: 0, force3D: false });

		//const tl = gsap.timeline();
		//this._particleGroup.position.z =200 + value;

		tl.to(headline, { scale: 250, x: 3150, y: 1400, duration: 3, opacity: 1, ease: 'none' }, 0);

		tl.to(this._heroVideo, { scale: 4, duration: 3, opacity: 0, volume: 0, ease: 'none', onUpdate: this.checkVideo }, 0);
		tl.to(subtitles, { duration: 3, opacity: 0, ease: 'none' }, 0);
		tl.to('.volumeButtons', { duration: 3, opacity: 0, x: 0, ease: 'none' }, 0);

		tl.to('.scrollToContinue', { duration: 3, opacity: 0, x: 200, ease: 'none' }, 10);

		tl.set(preloaderItem, { visibility: 'visible' }, 3);
		tl.set(preloaderItem, { visibility: 'hidden' }, 3.1);

		tl.to(Globals.LOGO_TOP_LEFT, { x: 0, ease: 'power1.out', duration: 3 }, 0);

		tl.fromTo(title1, { scale: 0, opacity: 1 }, { scale: 1, duration: 3, opacity: 1, ease: 'none' }, 1);
		tl.to(title1, { scale: 80, duration: 10, opacity: 1, ease: 'power3.in' }, 4);
		tl.set(title1, { visibility: 'visible' }, 13);
		tl.set(title1, { visibility: 'hidden' }, 13.1);

		tl.fromTo(title2, { scale: 0, opacity: 1 }, { scale: 1, duration: 3, opacity: 1, ease: 'none' }, 10);
		tl.to(title2, { scale: 80, duration: 10, opacity: 1, ease: 'power3.in' }, 13);
		tl.set(title2, { visibility: 'visible' }, 24);
		tl.set(title2, { visibility: 'hidden' }, 25);

		tl.fromTo(title3, { scale: 0, opacity: 1 }, { scale: 1, duration: 3, opacity: 1, ease: 'none' }, 21);
		tl.to(title3, { scale: 80, duration: 10, opacity: 1, ease: 'power3.in' }, 24);
		tl.set(title3, { visibility: 'visible' }, 35);
		tl.set(title3, { visibility: 'hidden' }, 36);

		gsap.set(preloaderItem, { opacity: 1 });
		//console.log(this._allVertices[0])
		//	return;

		for (var p = 0; p < this._allPointGroups.length; p++) {
			var currentVerticesGroup = this._allVerticePositions[p];
			var currentPointGroup = this._allPointGroups[p];
			var _length = currentVerticesGroup.length;
			/*console.log('----');
			console.log(currentVerticesGroup);
			console.log(currentPointGroup);*/
			for (var i = 0; i < _length; i++) {
				var currentPosition = currentVerticesGroup[i];

				currentPosition.positions.z = 1000;
				//this.updateVerticePosition(i, currentPosition);

				if (i === 0) {
					//		console.log(currentPosition);
				}
				tl.to(
					currentPosition.positions,
					{
						delay: Math.random() * 18,
						duration: 8 + Math.random() * 18,
						z: currentPosition.positions._originalZPos,
						onUpdate: this.updateVerticePosition,
						onUpdateParams: [i, currentPosition, currentPointGroup],
						ease: 'power1.out'
					},
					-4
				);
			}
		}
		return tl;
	};

	private checkVideo = () => {
		if (Globals.IS_MOBILE) {
		} else {
			if (this._heroVideo.volume === 0) {
				this._heroVideo.pause();
				this._heroVideo.style.display = 'none';
			} else {
				this._heroVideo.play();
				this._heroVideo.style.display = 'block';
			}
		}
	};

	private onUpdateReset = () => {};

	private moveHand = () => {
		const tl = gsap.timeline();
		tl.fromTo(this._particleGroup.position, { z: 200 - 250 }, { z: 1200 - 250, duration: 50, ease: 'power1.in' }, 0);
		tl.fromTo(this._handImageGroup.position, { z: 0 }, { z: 400 + 20 * 400, duration: 50 + 20 * 30, ease: 'linear.none' }, 0);
		tl.fromTo(this._handImageGroupOuter.position, { z: -1000 }, { z: 0, duration: 15, ease: 'linear.none' }, 20);

		return tl;
	};

	private distortHand = () => {
		const tl = gsap.timeline();
		for (var p = 0; p < this._allPointGroups.length; p++) {
			var currentVerticesGroup = this._allVerticePositions[p];
			var currentPointGroup = this._allPointGroups[p];
			var _length = currentVerticesGroup.length;

			for (var i = 0; i < _length; i++) {
				var currentPosition = currentVerticesGroup[i];
				var calcNewZ = currentPosition.positions._originalZPos + Math.random() * -500;
				tl.to(
					currentPosition.positions,

					{
						delay: (1 + Math.random() * 3) * 2,
						duration: (1.5 + Math.random() * 3) * 2,
						z: calcNewZ,
						onUpdate: this.updateVerticePosition,
						onUpdateParams: [i, currentPosition, currentPointGroup],
						ease: 'power1.in'
					},
					0
				);
			}
		}
		return tl;
	};

	public centerCTA = () => {
		const tl = gsap.timeline();

		/*gsap.set(Globals.CENTER_CTA.headline2, { transformOrigin: '50% 100%' });
		gsap.set(Globals.CENTER_CTA.giveAHandButton, { transformOrigin: '50% 0%' });

		gsap.set(Globals.CENTER_CTA.headline1, { transformOrigin: '50% 100%' });
		gsap.set(Globals.CENTER_CTA.browseDataSetButton, { transformOrigin: '50% 0%' });*/

		var initialScale = 0;
		tl.fromTo(Globals.CENTER_CTA.element, { scale: initialScale, opacity: 1 }, { scale: 1, duration: 10, opacity: 1, ease: 'none' }, 0);

		/*	tl.fromTo(Globals.CENTER_CTA.subHeadline, { scale: initialScale, opacity: 0 }, { scale: 1, duration: 10, opacity: 1, ease: 'power1.out' }, 0);
	tl.fromTo(Globals.CENTER_CTA.headline2, { scale: initialScale, opacity: 0 }, { scale: 1, duration: 10, opacity: 1, ease: 'power1.out' }, 1);
	tl.fromTo(Globals.CENTER_CTA.giveAHandButton, { scale: initialScale, opacity: 0 }, { scale: 1, duration: 10, opacity: 1, ease: 'power1.out' }, 1);
	tl.fromTo(Globals.CENTER_CTA.headline1, { scale: initialScale, opacity: 0 }, { scale: 1, duration: 10, opacity: 1, ease: 'power1.out' }, 2);
	tl.fromTo(Globals.CENTER_CTA.browseDataSetButton, { scale: initialScale, opacity: 0 }, { scale: 1, duration: 10, opacity: 1, ease: 'power1.out' }, 2);
	tl.fromTo(Globals.CENTER_CTA.buttonInverted, { scale: initialScale, opacity: 0 }, { scale: 1, duration: 10, opacity: 1, ease: 'power1.out' }, 4 - 1);
	*/
		//	tl.to(this._scene.fog, { far: 0, duration: 35, ease: 'power1.in' }, 0);

		tl.to(this._buttonInvertedOuter, { width: '220px', duration: 15, opacity: 1, ease: 'power1.out' }, 13);

		tl.to(Globals.GIVE_A_HAND_BUTTON.element, { x: 0, ease: 'power1.out', duration: 2 }, 5);
		tl.to(Globals.SUB_MENU.element, { x: 0, ease: 'power1.out', duration: 2 }, 5);

		tl.to(Globals.CENTER_CTA.element, { scale: 40, duration: 5, force3D: false, opacity: 1, ease: 'power1.in' }, 27);

		tl.set(Globals.CENTER_CTA.element, { visibility: 'visible' }, 32);
		tl.set(Globals.CENTER_CTA.element, { visibility: 'hidden' }, 32.1);

		return tl;
	};

	private onHandLoaded = gltf => {
		///	console.log(gltf);
		var child = gltf.scene.children[0];
		child.scale.set(6, 6, 6);
		this._hand = child;

		child.position.z = 300;
		this._hand.position.x = -0.07;
		this._hand.position.y = -0.14;

		// Add hand wireframe
		//	const wireframe = new THREE.WireframeGeometry(this._hand.children[1].children[0].geometry);
		//	const line = new THREE.LineSegments(wireframe);
		//	line.material = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 3, opacity: 0.25, transparent: true, linecap: 'square', linejoin: 'miter' });
		//this._particleGroup.add(line);

		this._mixer = new THREE.AnimationMixer(gltf.scene);

		const clips = gltf.animations;
		//console.log(clips);

		// Update the mixer on each frame
		const dirLight3 = new THREE.SpotLight(0xffffff, 2);
		dirLight3.position.set(-250, -200, 70);

		//	dirLight.castShadow = true;
		dirLight3.lookAt(child);
		//	console.log(child)
		//	this._scene.add(dirLight3);

		//	this._scene.add(gltf.scene);

		gltf.scene.position.z = 50;

		var animations = gltf.animations;

		for (var i: number = 0; i < animations.length; i++) {
			var getAnim = this._mixer.clipAction(animations[i]);
			if (i > 0) {
				getAnim.time = animations[i].duration / 2;

				if (i === 10) {
					getAnim.time = 0;
				} else if (i === 26 || i === 27) {
					getAnim.time = 0;
				}

				getAnim.paused = true;
				getAnim.play();
				getAnim.weight = 0;
			} else {
				this._currentAnimation = getAnim;
			}

			this._animationMixes.push(getAnim);
		}

		this._particleGroup.position.z = -100;
		//gsap.to(this._particleGroup.position, { duration: 5, delay: 0, z: 200, ease: 'power1.out' });
		if (Globals.IS_TOUCH_DEVICE) {
		} else {
			document.addEventListener('mousemove', this.onPointerMove);
		}

		document.addEventListener('touchstart', this.onPointerTouch);

		this._renderer.domElement.addEventListener('click', this.mouseClick);

		this.playHandAnimation(1);
		/*this.playHandAnimation(15)
		this.startAnim(6)
		this.spellLetter('o')
		this.setNewHand(2, null);*/

		/*
		// Play a specific animation
		const clip = THREE.AnimationClip.findByName(clips, 'P');
		console.log(clip);
		const action = this._mixer.clipAction(clip);
		action.play();
		 */
		this.handLoaded();
	};

	private mouseClick = event => {
		this._pointer.x = (event.clientX / this._innerWidth) * 2 - 1;
		this._pointer.y = -(event.clientY / this._innerHeight) * 2 + 1;
		this._raycaster.setFromCamera(this._pointer, this._camera);
		this._handObjectPool.mouseClick(this._raycaster, this._scene);
	};

	private handLoaded = () => {
		this.addPoints(this._hand.children[1].children[0].geometry.attributes.position.array);

		this._handLoadedCallback();
	};

	public setupScroll = () => {
		// Start Scroll Trigger
		var calculateScrollBasedOnNumberOfHands = '30000px'; //(Math.floor(Globals.TOTAL_GESTURE_COUNT / 40) + 100) * 100 + 'vh';
		var totalScrollForIntro = '5000px';
		//	document.body.style.height = 'calc(' + calculateScrollBasedOnNumberOfHands + ' + ' + totalScrollForIntro + ')';
		Globals.MAIN_VIEW_CONTAINER.style.height = Globals.SCROLL_HEIGHT + 'px';

		/*

			markers: { startColor: 'green', endColor: 'red', fontSize: '12px' },
		 */

		this._scrollTrigger = ScrollTrigger.create({
			trigger: Globals.MAIN_VIEW_CONTAINER,
			start: '-200px',
			end: 'bottom bottom',
			scrub: 2,
			animation: this.mainScroll(),
			onUpdate: self => {
				var getProgress = self.progress;
				//console.log(self);
				//Globals.HAND_SCENE.moveMainContainer(getProgress * 100);
				//	console.log('progress:', self.progress.toFixed(3), 'direction:', self.direction, 'velocity', self.getVelocity());
				this.closeHandView();
				Globals.SCROLL_DIRECTION = self.direction;

				if (getProgress >= 1) {
					//console.log('Reached the end');
					/*	if (this._datasetAdded === false) {
							console.log('adding to height')
							this._datasetAdded = true;
							Globals.MAIN_VIEW_CONTAINER.style.height = parseInt(Globals.MAIN_VIEW_CONTAINER.style.height) + 4000 + 'px';
							this._scrollTrigger.refresh()
						}*/
					//this.endIntroScene();
					/*		document.body.style.height = parseInt(document.body.style.height)+ 1000 + 'px';
						this._handObjectPool.turnOn = true;
						console.log(this._scrollTrigger)
						this._scrollTrigger.refresh();*/
				}
			}
		});

		Globals.MAIN_SCROLL_TRIGGER = this._scrollTrigger;

		if (Globals.DEBUG_AUTOPLAY === true) {
			gsap.delayedCall(3, this.autoScrollToEnd);
		}
	};

	private autoScrollToEnd = () => {
		// Auto scroll to first title
		function labelToScroll(timeline, label) {
			let st = Globals.MAIN_SCROLL_TRIGGER;

			//	console.log(timeline.labels);
			//	console.log(timeline);
			var getEndPosition = st.start + (st.end - st.start) * (timeline.labels[label] / timeline.duration());
			//	console.log(getEndPosition);

			return getEndPosition;
		}

		var getScrollPos = labelToScroll(Globals.MAIN_SCROLL_TRIGGER.animation, 'endLabel');
		gsap.to(window, { scrollTo: getScrollPos, duration: 100 * 2, ease: 'none' });
	};

	public scrollToFirstTitle = () => {
		// Auto scroll to first title
		function labelToScroll(timeline, label) {
			let st = Globals.MAIN_SCROLL_TRIGGER;

			//	console.log(timeline.labels);
			//	console.log(timeline);
			var getEndPosition = st.start + (st.end - st.start) * (timeline.labels[label] / timeline.duration());
			//	console.log(getEndPosition);

			return getEndPosition;
		}

		var getScrollPos = labelToScroll(Globals.MAIN_SCROLL_TRIGGER.animation, 'firstTitle');
		gsap.to(window, { scrollTo: getScrollPos, duration: 7, ease: 'expo.out' });
	};

	private autoScroll = () => {
		// Auto scroll to first title
		function labelToScroll(timeline, label) {
			let st = Globals.MAIN_SCROLL_TRIGGER;

			//	console.log(timeline.labels);
			//	console.log(timeline);
			var getEndPosition = st.start + (st.end - st.start) * (timeline.labels[label] / timeline.duration());
			//	console.log(getEndPosition);

			return getEndPosition;
		}

		var getScrollPos = labelToScroll(Globals.MAIN_SCROLL_TRIGGER.animation, 'firstTitle');
		gsap.to(window, { scrollTo: getScrollPos, duration: 7, ease: 'expo.out' });
	};

	private endIntroScene = () => {
		/*	ScrollTrigger.getAll().forEach(st => st.kill(true, true));
		document.body.style.overflowY = 'scroll';
		document.body.style.height = '100vh';
		window.scrollTo(0, 0);*/

		this._handObjectPool.turnOn = true;
	};

	private getScrollHeight = () => {
		return document.body.scrollHeight;
	};

	private addPoints = getHandPositionalData => {
		const geometryPointsWhite = new THREE.BufferGeometry();
		const verticesWhite = [];

		const geometryPointsRed = new THREE.BufferGeometry();
		const verticesRed = [];

		const geometryPointsBlue = new THREE.BufferGeometry();
		const verticesBlue = [];

		const verticesWhitePositions = [];
		const verticesRedPositions = [];
		const verticesBluePositions = [];

		this._allVerticePositions.push(verticesWhitePositions);
		this._allVerticePositions.push(verticesRedPositions);
		this._allVerticePositions.push(verticesBluePositions);

		var count = 0;

		this._allPlanes = [];

		this._particleGroupOuter.position.set(-0.07, -0.15, 0);

		var previousPositionX = -1;

		var length = getHandPositionalData.length;

		for (var i = 0; i < length; i += 6) {
			var x = getHandPositionalData[i];
			var y = getHandPositionalData[i + 1];
			var z = getHandPositionalData[i + 2];

			// Make sure points dont overlap (is on exact same position)
			if (x !== previousPositionX) {
				previousPositionX = x;
				//	previousPosition.y = y;
				//	previousPosition.z = z;

				var random = Math.floor(Math.random() * 3);

				if (random === 0) {
					verticesWhite.push(x, y, -10000);
					verticesWhitePositions.push({ positions: { x: x, y: y, z: -10000, _originalZPos: z }, index: count });
				} else if (random === 1) {
					verticesRed.push(x, y, -10000);
					verticesRedPositions.push({ positions: { x: x, y: y, z: -10000, _originalZPos: z }, index: count });
				} else if (random === 2) {
					verticesBlue.push(x, y, -10000);
					verticesBluePositions.push({ positions: { x: x, y: y, z: -10000, _originalZPos: z }, index: count });
				}

				count++;
			}
		}

		this._allVertices.push(verticesWhite);
		this._allVertices.push(verticesRed);
		this._allVertices.push(verticesBlue);

		/*console.log(this._allVertices);

		console.log('count : ' + count);*/

		geometryPointsWhite.setAttribute('position', new THREE.Float32BufferAttribute(verticesWhite, 3));
		geometryPointsRed.setAttribute('position', new THREE.Float32BufferAttribute(verticesRed, 3));
		geometryPointsBlue.setAttribute('position', new THREE.Float32BufferAttribute(verticesBlue, 3));
		this._allPointGroups = [geometryPointsWhite, geometryPointsRed, geometryPointsBlue];
		// @ts-ignore
		geometryPointsWhite.attributes.position.setUsage(THREE.DynamicDrawUsage);
		// @ts-ignore
		geometryPointsRed.attributes.position.setUsage(THREE.DynamicDrawUsage);
		// @ts-ignore
		geometryPointsBlue.attributes.position.setUsage(THREE.DynamicDrawUsage);

		this._geometryPointsWhite = geometryPointsWhite;

		var materialsWhite = new THREE.PointsMaterial({ color: 0xffffff, size: 0.2, map: null, depthTest: false, transparent: false });
		this._particlesWhiteMesh = new THREE.Points(geometryPointsWhite, materialsWhite);
		this._particleGroup.add(this._particlesWhiteMesh);

		var materialsRed = new THREE.PointsMaterial({ color: Globals.COLORS.red, size: 0.2, map: null, depthTest: false, transparent: false });
		this._particlesRedMesh = new THREE.Points(geometryPointsRed, materialsRed);

		this._particleGroup.add(this._particlesRedMesh);

		var materialsBlue = new THREE.PointsMaterial({ color: Globals.COLORS.blue, size: 0.2, map: null, depthTest: false, transparent: false });
		this._particlesBlueMesh = new THREE.Points(geometryPointsBlue, materialsBlue);
		this._particleGroup.add(this._particlesBlueMesh);
	};

	private onPointerTouch = event => {
		this._pointer.x = (event.targetTouches[0].x / this._innerWidth) * 2 - 1;
		this._pointer.y = -(event.targetTouches[0].y / this._innerHeight) * 2 + 1;
	};

	private onPointerMove = event => {
		this._pointer.x = (event.clientX / this._innerWidth) * 2 - 1;
		this._pointer.y = -(event.clientY / this._innerHeight) * 2 + 1;

		if (Globals.IS_TOUCH_DEVICE || Globals.HAND_SCENE_ACTIVE === false) {
		} else {
			const intersects = this._raycaster.intersectObjects(this._handObjectPool.mainGroup.children, false);
			if (intersects && intersects.length > 0) {
				//	console.log('here');
				document.body.style.cursor = 'pointer';
			} else {
				document.body.style.cursor = 'default';
			}
		}

		//	this.playHandAnimation(2, null);
		/*	this._camera.rotation.x = this._pointer.y / 1000;
		this._camera.rotation.y = (this._pointer.x * -1) / 1000;*/
	};

	private onWindowResize = () => {
		var width = window.innerWidth;
		var height = window.innerHeight;
		this._innerWidth = width;
		this._innerHeight = height;

		this._renderer.setSize(width, height);
		this._renderer.setViewport(0, 0, width, height);
		this._camera.fov = height / 1600;
		this._camera.aspect = width / height;
		this._camera.updateProjectionMatrix();

		this._handJoints.resize();
	};

	private animate = () => {
		this._stats.begin();
		requestAnimationFrame(this.animate);

		this._handJoints.update(this._scene, this._camera);

		var zPos = this._pointer.y * -1 * 2;
		if (zPos < 0) {
			zPos = zPos * -1;
		}

		zPos = zPos * -1;

		if (Globals.IS_TOUCH_DEVICE) {
		} else {
			gsap.to(this._camera.position, {
				x: ((this._pointer.x * -1) / 25) * this._onMouseMoveDivider.count,
				y: ((this._pointer.y * -1) / 25) * this._onMouseMoveDivider.count,
				z: zPos * this._onMouseMoveDivider.count * 3,
				ease: 'power1.out',
				duration: 1
			});
		}
		//gsap.to(this._camera.rotation, { x: this._pointer.y / 1000, y: (this._pointer.x * -1) / 1000, ease: 'power1.out', duration: 1 });

		const delta = this._clock.getDelta();

		if (this._mixer) this._mixer.update(delta);

		//	gsap.to(this._camera.rotation, { x: this._pointer.y / 500, y: this._pointer.x * - 1 / 500, ease: 'power1.in', duration: 0.5 });

		this._handObjectPool.onRender();

		this._camera.updateMatrixWorld();

		// find intersections

		this._raycaster.setFromCamera(this._pointer, this._camera);
		/*

				const intersects = raycaster.intersectObjects(this._handObjectPool.mainGroup.children, false);
				console.log(intersects);
				var isIntersection = false;
				if (intersects.length > 0) {
					if (isIntersection != intersects[0].object) {
						if (isIntersection) {
							//	isIntersection.material.color.setHex(0xff0000);
						}

						isIntersection = intersects[0].object;
						console.log(isIntersection.position.z);
						console.log(isIntersection.position);
					}
				} else {
					if (isIntersection) {
						console.log(isIntersection.position);
					}
					isIntersection = null;
				}
				*/

		/*		const intersects = this._raycaster.intersectObjects(this._particleGroup.children, false);

			if (intersects.length > 0) {
				if (this._INTERSECTED != intersects[0].object) {
					if (this._INTERSECTED) this._INTERSECTED.material.color.setHex(this._INTERSECTED.currentHex);

					this._INTERSECTED = intersects[0].object;
					this._INTERSECTED.currentHex = this._INTERSECTED.material.color.getHex();
					//	this._INTERSECTED.material.color.setHex( 0xff0000 );

					const offset = new THREE.Vector3();
					new THREE.Box3().setFromObject(this._INTERSECTED).getSize(offset);

					// Move label over hovered element
					label.position.set(
						hovered.object.position.x,
						offset.y / 2,
						hovered.object.position.x
					);
					//	console.log(this._INTERSECTED.position.x);
					var object = this._INTERSECTED;
					let pos = new THREE.Vector3();
					pos = pos.setFromMatrixPosition(object.matrixWorld);
					pos.project(this._camera);

					let widthHalf = window.innerWidth / 2;
					let heightHalf = window.innerHeight / 2;

					pos.x = pos.x * widthHalf + widthHalf;
					pos.y = -(pos.y * heightHalf) + heightHalf;
					pos.z = 0;

					//	console.log(pos);
				}
			} else {
				if (this._INTERSECTED) this._INTERSECTED.material.color.setHex(this._INTERSECTED.currentHex);

				this._INTERSECTED = null;
			}*/

		/*
		// Pick objects from view using normalized mouse coordinates
  raycaster.setFromCamera(mouse, camera);

  const [hovered] = raycaster.intersectObjects(scene.children);

  if (hovered) {
    // Setup label
    renderer.domElement.className = 'hovered';
    label.visible = true;
    labelDiv.textContent = hovered.object.name;

    // Get offset from object's dimensions
    const offset = new Vector3();
    new Box3().setFromObject(hovered.object).getSize(offset);

    // Move label over hovered element
    label.position.set(
      hovered.object.position.x,
      offset.y / 2,
      hovered.object.position.x
    );
  } else {
    // Reset label
    renderer.domElement.className = '';
    label.visible = false;
    labelDiv.textContent = '';
  }
		 */

		//this.checkZ();

		if (this._camera.postprocessing && this._camera.postprocessing.enabled) {
			this._camera.renderCinematic(this._scene, this._renderer);
		} else {
			//scene.overrideMaterial = null;

			//renderer.clear();
			this._renderer.render(this._scene, this._camera);
		}

		//	stats.update();
		this._stats.end();
	};

	public convertLetterToNumber = (letter: string) => {
		const alphaVal = letter.toLowerCase().charCodeAt(0) - 97 + 1;
		return alphaVal;
	};

	public setNewHand = (count: number, onCompleteCallback) => {
		/*	console.log('*** setNewHand : ' + count);
		console.log(onCompleteCallback);*/
		if (count === 0) {
			this.stopSpelling();
			return;
		}

		//console.log(this._animationMixes);

		if (count > this._animationMixes.length) {
			count = 0;
			//	console.log('Letter not year created - count : ' + count);
		}
		//	console.log('count : ' + count);
		var getHandAnimation: AnimationAction = this._animationMixes[count];
		//	console.log(getHandAnimation);

		if (count === 26) {
			// We use the other Z
			getHandAnimation = this._animationMixes[27];
			//	console.log('---- setting new hand - 27');
		}

		//	console.log(getHandAnimation);

		var animTime = 0.4 * 10;
		var delay = 0.3 * 1 * 1;

		if (!onCompleteCallback) {
			delay = 0;
		}

		if (count === 7) {
			// G -- Hand needs to twist more - so we are making this one slower
			animTime = 1 * 1 * 1;
		}
		/*	if (count === 8) {
			// G -- Hand needs to twist more - so we are making this one slower
			animTime = 1 * Globals.GAME_SETTINGS.handSpellSlowdown * 1;
		}*/
		if (getHandAnimation !== this._currentAnimation) {
			if (this._previousLetter === 9 && count === 14) {
				// I to N (fingers collide - trying to fix that)
				//console.log('I to N');
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime, weight: 0, ease: 'power1.easeIn' });
				gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0.3, ease: 'linear.easeNone' });

				gsap.to(this._animationMixes[0], { delay: delay * 1.2, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay * 1.2, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			}
			if (count === 10 || count == 26) {
				// I to N (fingers collide - trying to fix that)
				//console.log('I to N');
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime, weight: 1, ease: 'power1.easeIn' });
				gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				if (count === 26) {
					this.playHandAnimation(count + 1);
				} else {
					this.playHandAnimation(count);
				}
				//gsap.to(this._animationMixes[0], { delay: delay * 1.2, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				//gsap.to(getHandAnimation, { delay: delay * 1.2, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			} else if (this._previousLetter === 7 && count === 5) {
				animTime = 1 * 1 * 1;
				// From G to E
				//console.log('I to N');
				//	console.log('--- HERE');
				//	console.log(this._currentAnimation);
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime * 1, time: 0.3, ease: 'linear.easeNone' });
				//gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0.3, ease: 'linear.easeNone' });

				gsap.to(this._currentAnimation, { delay: delay * 1.3, duration: 1, weight: 0, ease: 'linear.easeNone' });
				gsap.to(this._currentAnimation, { delay: delay * 1.3 + 1, duration: 1, time: 3, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay * 1.3 + 0.2, duration: animTime * 1, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			} else if (this._previousLetter === 8 && count === 20) {
				animTime = 1 * 1 * 1;
				// From G to E
				//console.log('I to N');
				//	console.log('--- HERE');
				//	console.log(this._currentAnimation);
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime * 1, time: 0.3, ease: 'linear.easeNone' });
				//gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0.3, ease: 'linear.easeNone' });

				gsap.to(this._currentAnimation, { delay: delay * 1.3 + 0.5, duration: 1, weight: 0, ease: 'linear.easeNone' });
				gsap.to(this._currentAnimation, { delay: delay * 1.3 + 0.5 + 1, duration: 1, time: 3, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay * 1.3 + 1, duration: animTime * 1, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			} else {
				gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 1, ease: 'linear.easeNone' });
				//	console.log(this._currentAnimation);
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			}

			//	gsap.to(this._currentAnimation, {delay: 0.3, duration: animTime, weight: 0, ease: 'linear.easeNone'});
			//	gsap.to(getHandAnimation, {delay: 0.3, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback});
		} else {
			gsap.to(this._animationMixes[0], { delay: 0.3, duration: animTime, weight: 1, ease: 'linear.easeNone' });

			// Its the same letter - so we move the hand slightly on the X axis
			/*if (Globals.IN_GAME !== true) {
				if (this._movedToLeft) {
					gsap.to(this._hand.position, { delay: 0.3, duration: animTime, x: 0, onComplete: onCompleteCallback });
					this._movedToLeft = false;
				} else {
					gsap.to(this._hand.position, { delay: 0.3, duration: animTime, x: -0.1, onComplete: onCompleteCallback });
					this._movedToLeft = true;
				}
			} else {*/
			gsap.delayedCall(0.3 + animTime, onCompleteCallback);
			//}
		}

		this._previousLetter = count;
		this._currentAnimation = getHandAnimation;
	};

	public playHandAnimation = number => {
		this.stopSpelling();
		gsap.delayedCall(0.3, this.startAnim, [number]);
	};

	private startAnim = number => {
		//	console.log('startAnim - ' + number);

		var getAnim = this._animationMixes[number];
		//	console.log(getAnim);
		getAnim.time = 0;
		getAnim.paused = false;
		getAnim.play();
		getAnim.weight = 1;
	};

	public spellLetter = letter => {
		//	console.log('**** spellLetter - ' + letter);
		this.stopSpelling();

		this._sentenceArray = [0];
		this._currentWordInSentenceCount = 0;
		this.nextLetter();
	};

	public spellSentence = sentence => {
		//	console.log('**** spellSentence - ' + sentence);
		this.stopSpelling();
		//	console.log(sentence);
		sentence = sentence.split('');
		this._sentenceArray = [];
		for (var i = 0; i < sentence.length; i++) {
			var currentLetter = sentence[i];
			var letterToNumber = this.convertLetterToNumber(currentLetter);
			//	console.log('currentLetter : ' + currentLetter + ' , letterToNumber: ' + letterToNumber);
			this._sentenceArray.push(letterToNumber);
		}
		this._currentWordInSentenceCount = 0;
		this.nextLetter();
	};

	public spellPreloaderAnim = () => {
		// Animation; 1, 2, 3, 4, 5
		this._sentenceArray = [27, 28, 29, 30];
		this._currentWordInSentenceCount = 0;
		this.nextLetter();
	};

	private nextLetter = () => {
		var length = this._sentenceArray.length;

		if (length === 1) {
			this.setNewHand(this._sentenceArray[this._currentWordInSentenceCount], null);
		} else {
			this.setNewHand(this._sentenceArray[this._currentWordInSentenceCount], this.nextLetter);
		}
		this._currentWordInSentenceCount++;

		if (this._currentWordInSentenceCount >= this._sentenceArray.length) {
			this._currentWordInSentenceCount = 0;
		}
	};

	public stopSpelling = () => {
		//	console.log('***** stopspelling');
		this._currentWordInSentenceCount = 0;
		this._sentenceArray = [];

		gsap.killTweensOf(this._currentAnimation);
		gsap.killTweensOf(this._animationMixes[0]);
		gsap.killTweensOf(this._animationMixes);
		//gsap.killTweensOf(this._hand.position);
		//gsap.killTweensOf(this._hand.position);
		//if (Globals.IN_GAME !== true) {
		gsap.to(this._hand.position, { delay: 0.0, duration: 0.3, x: 0, onComplete: null });
		//}
		//	gsap.killTweensOf(this._animationMixes);
		gsap.to(this._animationMixes, { duration: 0.3, weight: 0 });

		this._currentAnimation = null;
		this._movedToLeft = false;

		/*	var getHandAnimation: AnimationAction = this._animationMixes[0];
		gsap.to(this._currentAnimation, { delay: 0, duration: 0.2, weight: 0, ease: 'power2.out' });
		gsap.to(getHandAnimation, { duration: 0.2, weight: 1, ease: 'power2.out' });*/
	};

	public getPositionIn3D = (posX, posY) => {
		//https://stackoverflow.com/questions/13055214/mouse-canvas-x-y-to-three-js-world-x-y-z
		var vec = new THREE.Vector3(); // create once and reuse
		var pos = new THREE.Vector3(); // create once and reuse

		vec.set((posX / window.innerWidth) * 2 - 1, -(posY / window.innerHeight) * 2 + 1, 0.5);

		vec.unproject(this._camera);

		vec.sub(this._camera.position).normalize();

		var distance = -this._camera.position.z / vec.z;

		pos.copy(this._camera.position).add(vec.multiplyScalar(distance));

		return pos;
	};

	public openHandView = (newIntersection?) => {
		if (this._handViewOpen === false) {
			gsap.killTweensOf(Globals.HAND_DATA_ELEMENT);
			gsap.to(this._handJoints.position, { z: -12, y: 0, ease: 'power1.out', duration: 0.5 });
			if (window.innerWidth > 1000) {
				gsap.to(Globals.HAND_DATA_ELEMENT, { x: 0, ease: 'power1.out', duration: 0.5 });
			}
			gsap.to(this._handIDNumber, { count: parseInt(Globals.PREVIOUSLY_CLICKED_HAND.userData.data.timeStamp), ease: 'power1.out', duration: 0.5, onUpdate: this.updateIDNumber });
			gsap.to(this._onMouseMoveDivider, { count: 0, ease: 'power1.out', duration: 0.5 });
			this._handViewOpen = true;

			var getStatusHeadline = document.querySelector('.statusHeadline');

			gsap.to('.status1', { opacity: 1, ease: 'power1.out', duration: 0.5 });
			gsap.to('.circleStatus1', { backgroundColor: 'rgba(255,255,255,1)', ease: 'power1.out', duration: 0.5 });
			gsap.to('.status2', { opacity: 1, ease: 'power1.out', duration: 0.5 });
			gsap.to('.circleStatus2', { backgroundColor: 'rgba(255,255,255,1)', ease: 'power1.out', duration: 0.5 });
			//console.log(Globals.PREVIOUSLY_CLICKED_HAND.userData.data)
			if (Globals.PREVIOUSLY_CLICKED_HAND.userData.data.status === 'approved_keypoints_manually_adjusted') {
				gsap.to('.status3', { opacity: 1, ease: 'power1.out', duration: 0.5 });

				gsap.to('.circleStatus3', { backgroundColor: 'rgba(255,255,255,1)', ease: 'power1.out', duration: 0.5 });
				getStatusHeadline.innerHTML = 'APPROVAL STATUS 3 of 3';
			} else {
				//gsap.to('.status2', {opacity:0.5, ease: 'power1.out', duration: 0.5});
				gsap.to('.status3', { opacity: 0.5, ease: 'power1.out', duration: 0.5 });
				//	gsap.to('.circleStatus2', {backgroundColor: 'rgba(255,255,255,0)', ease: 'power1.out', duration: 0.5});
				gsap.to('.circleStatus3', { backgroundColor: 'rgba(255,255,255,0)', ease: 'power1.out', duration: 0.5 });
				getStatusHeadline.innerHTML = 'APPROVAL STATUS 2 of 3';
			}
		}
	};

	private updateIDNumber = () => {
		var getID = Globals.HAND_DATA_ELEMENT.querySelector('.uniqueNumber');
		//gsap.to(getID, { innerHTML: Globals.PREVIOUSLY_CLICKED_HAND.userData.data.timestamp, ease: 'power1.out', duration: 0.5 });
		getID.innerHTML = String(
			Math.floor(this._handIDNumber.count)
				.toString()
				.padStart(10, '0')
		);
	};

	public clickCloseHandView = () => {
		this.closeHandView();
	};

	public closeHandView = (newIntersection?) => {
		//console.log('this._handViewOpen : ' + this._handViewOpen);
		if (this._handViewOpen === true) {
			this._handViewOpen = false;

			if (Globals.PREVIOUSLY_CLICKED_HAND != null && Globals.PREVIOUSLY_CLICKED_HAND !== newIntersection) {
				gsap.to(Globals.PREVIOUSLY_CLICKED_HAND.rotation, {
					x: Globals.PREVIOUSLY_CLICKED_HAND.userData._storeRotation.x,
					y: Globals.PREVIOUSLY_CLICKED_HAND.userData._storeRotation.y,
					z: Globals.PREVIOUSLY_CLICKED_HAND.userData._storeRotation.z,
					ease: 'power1.inOut',
					duration: 0.5
				});
				gsap.to(Globals.PREVIOUSLY_CLICKED_HAND.position, {
					z: Globals.PREVIOUSLY_CLICKED_HAND.userData._storePosition.z,
					x: Globals.PREVIOUSLY_CLICKED_HAND.userData._storePosition.x,
					y: Globals.PREVIOUSLY_CLICKED_HAND.userData._storePosition.x,
					ease: 'power1.inOut',
					duration: 0.5
				});

				Globals.PREVIOUSLY_CLICKED_HAND = null;
			}
			if (newIntersection == null) {
			}
			gsap.to(Globals.HAND_DATA_ELEMENT, { x: '50vw', ease: 'power1.in', duration: 0.5, delay: 0.1 });
			gsap.to(this._handJoints.position, { z: 20, y: -0.2, ease: 'power1.in', duration: 0.5 });
			gsap.to(this._onMouseMoveDivider, { count: 1, ease: 'power1.in', duration: 0.5 });
		}
	};

	public getHand = () => {
		return this._hand;
	};
}
