import { Signal } from '../../lib/com/hellomonday/signals/Signal';

export class CameraFeed {
	public completeSignal: Signal = new Signal();
	public devicesFound: Signal = new Signal();

	private _video: HTMLVideoElement;
	private _faceMode: string = 'user'; // 'user' 'environment'

	private _videoSourceId: string;

	private _stream;

	constructor() {
		// List cameras and microphones.
		this._stream = navigator.mediaDevices
			.enumerateDevices()
			.then(this.setupCameraFeed)
			.catch(function(err) {
				console.log(err.name + ': ' + err.message);
			});
	}

	private setupCameraFeed = devices => {
		let videoSourceId;

		for (let i = 0; i < devices.length; i++) {
			const sourceInfo = devices[i];

			if (sourceInfo.kind == 'videoinput') {
				videoSourceId = sourceInfo.deviceId;
				if (sourceInfo.kind == 'video') {
					videoSourceId = sourceInfo.id;
				}
			}
		}

		this._videoSourceId = videoSourceId;

		this._video = document.createElement('video');

		// This is important in IOS 12 (NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission)
		this._video.setAttribute('autoplay', '');
		this._video.setAttribute('muted', '');
		this._video.setAttribute('playsinline', '');

		this.devicesFound.dispatch();

		this.update();
	};

	private update = () => {
		let parent = this;
		// let ratio = window.innerWidth / window.innerHeight;

		let options;

		// let res = 'Device is not Android Phone';
		// let userAgent = navigator.userAgent.toLowerCase();
		// let Android = userAgent.indexOf('android') > -1;

		// if (Android) {
		// 	options = { audio: false, video: true, facingMode: this._faceMode, width: { ideal: window.innerHeight }, height: { ideal: window.innerWidth } };
		// } else {
		// 	options = { audio: false, video: true, facingMode: this._faceMode, width: { ideal: window.innerWidth }, height: { ideal: window.innerHeight } };
		// }
		options = {
			audio: false,
			video: { facingMode: 'user', width: 1920, height: 1080 }
			// facingMode: this._faceMode,
			// mandatory:{
			//
			// }

			//	optional: this._videoSourceId ? [{ sourceId: this._videoSourceId }] : []
		};

		if (navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia({
					audio: false,
					video: options
				})
				.then(function(stream) {
					parent._video.srcObject = stream;

					let promise = parent._video.play();

					if (promise !== undefined) {
						promise
							.then(() => {
								// console.log('CameraFeed: play');
								parent.completeSignal.dispatch();
							})
							.catch(error => {
								console.log(error);
							});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		} else {
			console.log('getUserMedia not found');
		}
	};

	public pause = () => {
		this._video.pause();
		// console.log('CameraFeed: pause');
	};

	public play = () => {
		// this.update();
		let promise = this._video.play();

		if (promise !== undefined) {
			promise
				.catch(error => {
					// Auto-play was prevented
					console.log(error);
				})
				.then(() => {
					// console.log('CameraFeed: play');
				});
		}
	};

	public captureImage = () => {
		let canvas = document.createElement('canvas');
		const image = new Image();
		canvas.width = this._video.videoWidth;
		canvas.height = this._video.videoHeight;
		canvas.getContext('2d').drawImage(this._video, 0, 0, this._video.videoWidth, this._video.videoHeight);
		canvas.toBlob(blob => {
			let src = URL.createObjectURL(blob);
			//@ts-ignore
			image._blob = blob;
			image.src = src;
		}, 'image/jpeg');

		canvas = null;

		return image;
	};

	public stopCamera = () => {
		// A video's MediaStream object is available through its srcObject attribute
		const mediaStream = this._video.srcObject;

		// Through the MediaStream, you can get the MediaStreamTracks with getTracks():
		try {
			//@ts-ignore
			const tracks = mediaStream.getTracks();

			// Tracks are returned as an array, so if you know you only have one, you can stop it with:
			tracks[0].stop();

			// Or stop all like so:
			tracks.forEach(track => track.stop());
		} catch (e) {
			console.log(e);
		}
	};

	get facingMode() {
		return this._faceMode;
	}

	set facingMode(value: string) {
		if (value === 'environment' || value === 'user') {
			this._faceMode = value;

			this.update();
		}
	}

	get video() {
		return this._video;
	}
}
