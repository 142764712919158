export const HAND_FACING_PALM: string = 'palm';
export const HAND_FACING_SIDE_VIEW: string = 'side-view';
export const HAND_FACING_DORSAL: string = 'dorsal';

export const HAND_TYPE_LEFT: string = 'left';
export const HAND_TYPE_RIGHT: string = 'right';

export const STATUS_APPROVED_KEYPOINTS_MANUALLY_ADJUSTED: string = 'approved_keypoints_manually_adjusted';
export const STATUS_APPROVED: string = 'approved';
export const STATUS_IN_REVIEW: string = 'in-review';
export const STATUS_REJECTED: string = 'rejected';

interface KeyPoint {
	x: number;
	y: number;
	z: number;
}

export class Gesture {
	public image: string = ''; // base64 encoded
	public upVotes: number = 0;
	public downVotes: number = 0;
	public status: string = STATUS_IN_REVIEW;
	public timeStamp: string = '';
	public random: number = 0;
	// public hand: string = HAND_TYPE_LEFT;
	// public handFacing: string = HAND_FACING_PALM;
	public id: number = 0;
	public key_point_0: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_1: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_2: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_3: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_4: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_5: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_6: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_7: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_8: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_9: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_10: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_11: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_12: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_13: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_14: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_15: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_16: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_17: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_18: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_19: KeyPoint = { x: 0, y: 0, z: 0 };
	public key_point_20: KeyPoint = { x: 0, y: 0, z: 0 };
}
