export class ImageUtils {
	/**
	 *
	 * @param image
	 * @param x
	 * @param y
	 * @param w
	 * @param h
	 * returns HTMLCanvasElement
	 */
	public static crop(image: HTMLCanvasElement | HTMLImageElement, x: number, y: number, w: number, h: number) {
		let canvas = document.createElement('canvas') as HTMLCanvasElement;
		let ctx = canvas.getContext('2d');

		canvas.width = w;
		canvas.height = h;

		ctx.drawImage(image, x, y, w, h, 0, 0, w, h);

		return canvas;
	}

	/**
	 *
	 * @param image
	 * @param flipH
	 * @param flipV
	 * @param w
	 * @param h
	 * returns HTMLCanvasElement
	 */
	public static flip(image: HTMLImageElement, flipH: boolean, flipV: boolean, w: number, h: number) {
		let canvas = document.createElement('canvas') as HTMLCanvasElement;
		let ctx = canvas.getContext('2d');

		let scaleH = flipH ? -1 : 1, // Set horizontal scale to -1 if flip horizontal
			scaleV = flipV ? -1 : 1, // Set verical scale to -1 if flip vertical
			posX = flipH ? w * -1 : 0, // Set x position to -100% if flip horizontal
			posY = flipV ? h * -1 : 0; // Set y position to -100% if flip vertical

		canvas.width = w;
		canvas.height = h;

		//ctx.save(); // Save the current state
		ctx.scale(scaleH, scaleV); // Set scale to flip the image
		ctx.drawImage(image, posX, posY, w, h); // draw the image
		//ctx.restore(); // Restore the last saved state

		return canvas;
	}
}
