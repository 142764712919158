import { Hands } from '@mediapipe/hands';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { clamp } from '../../lib/com/hellomonday/utils/MathUtils';

const BOUNDING_BOX_PADDING: number = 40;

export class MediaPipeClient {
	public onResultSignal: Signal = new Signal();
	private _hands: Hands;

	constructor() {
		this._hands = new Hands({
			locateFile: file => {
				return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1646424915/${file}`;
			}
			//0.3.1632795355
		});

		this._hands.setOptions({
			selfieMode: false,
			maxNumHands: 1,
			modelComplexity: 1,
			minDetectionConfidence: 0.5,
			minTrackingConfidence: 0.5
		});

		this._hands.onResults(this.onResults);
	}

	public async estimate(image) {
		await this._hands.send({ image: image });
	}

	private onResults = results => {
		console.log(results);

		if (results.multiHandLandmarks.length === 0) {
			this.onResultSignal.dispatch(null);
		} else {
			let normalized = this.getNormalizedLandmarks(results.multiHandLandmarks[0], results.image);
			let boundingBox = this.calculateBoundingBox(normalized, results.image.width, results.image.height);
			//let centeredLandmarks = this.getCenteredLandmarks(results.multiHandLandmarks[0], results.image);
			let flattenedLandmarks = this.getFlattenedLandmarks(results.multiHandLandmarks[0], results.image);
			this.onResultSignal.dispatch(flattenedLandmarks, boundingBox, { width: results.image.width, height: results.image.height });
		}
	};

	private calculateBoundingBox = (landmarks, width, height) => {
		let l = landmarks.length;
		let xValues = [];
		let yValues = [];

		for (let i = 0; i < l; i++) {
			xValues.push(landmarks[i].x);
			yValues.push(landmarks[i].y);
		}

		let boundingBox = {
			topLeft: [clamp(Math.min(...xValues) - BOUNDING_BOX_PADDING, 0, width), clamp(Math.min(...yValues) - BOUNDING_BOX_PADDING, 0, height)],
			bottomRight: [clamp(Math.max(...xValues) + BOUNDING_BOX_PADDING, 0, width), clamp(Math.max(...yValues) + BOUNDING_BOX_PADDING, 0, height)]
		};

		return boundingBox;
	};

	private getNormalizedLandmarks = (landmarks, image) => {
		let w = image.width;
		let h = image.height;
		let l = landmarks.length;
		let normalized = [];

		for (let i = 0; i < l; i++) {
			// normalized.push({x: w - (landmarks[i].x * w), y: h - (landmarks[i].y * h), z: landmarks[i].z});
			normalized.push({ x: landmarks[i].x * w, y: landmarks[i].y * h, z: landmarks[i].z });
		}

		return normalized;
	};

	private getFlattenedLandmarks = (landmarks, image) => {
		let w = image.width;
		let h = image.height;
		let l = landmarks.length;
		let flattened = [];

		for (let i = 0; i < l; i++) {
			flattened.push([landmarks[i].x * w, landmarks[i].y * h, landmarks[i].z]);
		}

		return flattened;
	};

	private getCenteredLandmarks = (landmarks, image) => {
		// let w = image.width;
		// let h = image.height;
		// let hw = Math.round(w * 0.5);
		// let hh = Math.round(h * 0.5);
		let l = landmarks.length;
		let centered = [];

		for (let i = 0; i < l; i++) {
			// normalized.push({x: w - (landmarks[i].x * w), y: h - (landmarks[i].y * h), z: landmarks[i].z});
			centered.push({ x: 0.5 - landmarks[i].x, y: 0.5 - landmarks[i].y, z: landmarks[i].z });
		}

		return centered;
	};
}
