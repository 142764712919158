import { CameraFeed } from '../camera/CameraFeed';
import { gsap } from 'gsap';
import * as THREE from 'three';
import { Globals } from '../data/Globals';

import { Texture } from 'three';

export class LoadTextureForPlane {
	private _plane: THREE.Mesh;

	constructor(plane: THREE.Mesh) {
		this._plane = plane;
		//	if (plane.userData.hasTextureLoaded === false) {
		plane.userData.hasTextureLoaded = true;
		this.loadTexture();
		//	}
	}

	private loadTexture = () => {
		const loader: THREE.TextureLoader = new THREE.TextureLoader();
		var getAllData = Globals.DATA_MANAGER.getAllData();
		var getRandomEntry = getAllData[Math.floor(Math.random() * (getAllData.length - 1))].data;
		var getRandomImage = getRandomEntry.image;

		/*console.log('getRandomEntry', getRandomEntry.timeStamp);
		console.log('total data : ' + getAllData.length)*/

		this._plane.userData.data = getRandomEntry;

		// load a resource
		loader.load(
			// resource URL
			getRandomImage,

			// onLoad callback
			this.textureOnLoad,

			// onProgress callback currently not supported
			undefined,

			// onError callback
			function(err) {
				console.log(getRandomEntry);
				console.error('An error happened.');
			}
		);
	};

	private textureOnLoad = (texture: Texture) => {
		texture.minFilter = THREE.LinearFilter;
		texture.needsUpdate = true;
		var getMaterial: THREE.MeshBasicMaterial = this._plane.material as THREE.MeshBasicMaterial;
		getMaterial.map = texture;
		getMaterial.map.needsUpdate = true;
		getMaterial.needsUpdate = true;

		// Calculate ratio of image to plane

		var ratioHeight = texture.image.width / texture.image.height;
		this._plane.userData._storeWidth = texture.image.width;
		this._plane.userData._storeHeight = texture.image.height;
		this._plane.userData._ratio = ratioHeight;
		//console.log(ratioHeight);
		this._plane.scale.x = 1 * ratioHeight;
		this._plane.scale.y = 1;
	};

	//
}
