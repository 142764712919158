import * as THREE from 'three';
import { DragControls } from '../controls/DragControls';
import { MeshLine, MeshLineMaterial } from 'threejs-meshline';
import { gsap } from 'gsap';
import { CSS2DObject, CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { clamp } from '../../../lib/com/hellomonday/utils/MathUtils';
import { HandScene } from '../../3d/HandScene';

export class HandJoints extends THREE.Group {
	private _objects = [];
	private _sphereGroup = [];
	private _lineGeometryArray = [];
	private _video;
	private _ratio: number;

	private _width: number;
	private _height: number;

	private _dragControls: DragControls;

	private _lineMeshes = [];
	private _lineMaterial;

	private _textureOffset = { value: 1 };

	private _lineMaterials = [];

	private _tooltipRenderer: CSS2DRenderer;
	private _element;

	private _fingerColors: Array<number> = [0xe9a99b, 0xe99ba0, 0xe99bb1, 0xd796d4, 0x8a82b7];
	private _highlightColor: number = 0xffa800;

	private _cropOffset = { x: 0, y: 0 };

	private _originalPoints;
	private _userPoints;

	private _mainGroup = new THREE.Group();
	private _multiplyValues = 0.15;

	// private _hand;

	private _datasetScene: HandScene;

	constructor(ratio, camera, renderer, element, datasetScene: HandScene, width, height) {
		//, hand) {
		super();

		this._datasetScene = datasetScene;

		// this._hand = hand;
		this._ratio = ratio;

		this._width = width;
		this._height = height;

		this._element = element;

		//this._dragControls = new DragControls(this._objects, camera, renderer.domElement); //, [{axis: 'z', active: true, min: -0.001, max: -0.001}]);
		/*this._dragControls.addEventListener('dragstart', this.onDragStart);
		this._dragControls.addEventListener('drag', this.onDrag);
		this._dragControls.addEventListener('dragend', this.onDragEnd);*/

		//	this._dragControls.addEventListener('hoveron', this.onHoverOver);
		//	this._dragControls.addEventListener('hoveroff', this.onHoverOut);

		/*	this._tooltipRenderer = new CSS2DRenderer();
		this._tooltipRenderer.setSize(window.innerWidth, window.innerHeight);
		this._tooltipRenderer.domElement.classList.add('tooltipRenderer');*/
		//element.appendChild(this._tooltipRenderer.domElement);

		/*const geometry = new THREE.BoxGeometry(0.11, 0.11, 0.11);
		const material = new THREE.MeshBasicMaterial({ color: 0x113311, opacity: 0.8, transparent: true });
		const cube = new THREE.Mesh(geometry, material);*/
		//this._mainGroup.add(cube);

		this.add(this._mainGroup);

		//gsap.to(this._mainGroup.rotation, { y: Math.PI * 2, duration: 10, ease: 'power2.inOut', repeat: -1 });

		/*	this._mainGroup.position.z = -0.7;
		this._mainGroup.position.x = -1.2;
		this._mainGroup.position.y = 0.5;*/

		// let texture = new THREE.CanvasTexture(this.generateTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping);

		// this._lineMaterial = new MeshLineMaterial({
		// 	// color: 0xc9718a,
		// 	color: true,
		// 	// transparent: true,
		// 	lineWidth: 0.0015,
		// 	useMap: true,
		// 	map: texture,
		// 	dashArray: 0.1, dashRatio: 0.1,
		// 	//transparent: true,opacity: 0.75
		// }); //  dashArray: 0.1, dashRatio: 0.1,

		for (let i = 0; i < 5; i++) {
			this._lineMaterials.push(
				new MeshLineMaterial({
					color: new THREE.Color(this._fingerColors[i]),
					// color: true,
					// transparent: true,
					lineWidth: 0.0015 * this._multiplyValues
					// useMap: true,
					// map: texture.clone(),
					// dashArray: 0.1, dashRatio: 0.1,
					//transparent: true,opacity: 0.75
				})
			);
		}

		// gsap.to(this._textureOffset, {duration: 5, value: 2, repeat: -1, yoyo: true, onUpdate: this.test})
		// gsap.to(this._lineMaterial.map.offset, {duration: 5, x: 10, repeat: -1, yoyo: true, onUpdate: this.test});
		// gsap.to(this._lineMaterial, {duration: 5, dashOffset: 1, repeat: -1, ease: 'none'});

		// this.addGui();
	}

	public reset = () => {
		let l = this._lineMeshes.length;

		for (let i = 0; i < l; i++) {
			this.remove(this._lineMeshes[i]);
			this._lineMeshes[i] = null;
		}

		l = this._objects.length;

		for (let i = 0; i < l; i++) {
			this.remove(this._objects[i]);
			this._objects[i] = null;
		}

		this._objects = [];

		//this._dragControls.updateObjects(this._objects);
	};

	public setNewWidthAndHeight = (width, height, ratio) => {
		this._width = width;
		this._height = height;
		this._ratio = ratio;
	};

	public updatePoints = (points, width, height) => {
		this._originalPoints = points;
		this._userPoints = [...points];

		let textureWidth = 0.2 * -this._ratio;
		let textureHeight = 0.2;

		let l = points.length;

		//  thumb: [1, 2, 3, 4],
		// 	indexFinger: [5, 6, 7, 8],
		// 	middleFinger: [9, 10, 11, 12],
		// 	ringFinger: [13, 14, 15, 16],
		// 	pinky: [17, 18, 19, 20],
		// 	palmBase: [0]

		for (let i = 0; i < l; i++) {
			let geometry = new THREE.SphereGeometry(i === 0 ? 0.004 * this._multiplyValues : 0.002 * this._multiplyValues, 32, 32);
			let material = new THREE.MeshBasicMaterial({ color: 0xff0000 }); //0xad4b2b
			let sphere = new THREE.Mesh(geometry, material);
			//@ts-ignore
			sphere._id = i;
			//console.log(points[i].x);
			sphere.position.x = (points[i].x - width * 0.5) / 6000;
			sphere.position.y = ((points[i].y - height * 0.5) / 6000) * -1;
			sphere.position.z = clamp(points[i].z, -0.01, 0.01) * this._multiplyValues; //points[i][2];//-0.0001; //points[i][2] * 0.01; //clamp(points[i][2], -0.1, 0.1);//-0.0001; //points[i][2];

			//	sphere.position.x = 0;
			//	sphere.position.y = 0;
			//sphere.position.z = clamp(points[i].z, -0.01, 0.01) * this._multiplyValues; //points[i][2];//-0.0001; //points[i][2] * 0.01; //clamp(points[i][2], -0.1, 0.1);//-0.0001; //points[i][2];

			//console.log(sphere.position);

			sphere.name =
				(i === 0 ? 'wrist' : i < 5 ? 'thumb' : i > 4 && i < 9 ? 'indexFinger' : i > 8 && i < 13 ? 'middleFinger' : i > 12 && i < 17 ? 'ringFinger' : 'pinky') +
				(i === 0 ? '' : ': [' + (((i - 1) % 4) + 1) + ']');

			let tooltipDiv = document.createElement('div');
			tooltipDiv.className = 'tooltip';
			// tooltipDiv.textContent = 'x: 0, y: 0, z: 0';
			let tooltipObject = new CSS2DObject(tooltipDiv);
			tooltipObject.position.y = 0.005;
			sphere.add(tooltipObject);

			// @ts-ignore
			sphere._tooltip = tooltipDiv;

			// @ts-ignore
			sphere._tooltipObject = tooltipObject;

			this._mainGroup.add(sphere);
			this._objects.push(sphere);
		}

		this.updateLines();
	};

	public updatePointsNew = (points, width, height) => {
		this._originalPoints = points;
		this._userPoints = [...points];

		let l = points.length;

		//  thumb: [1, 2, 3, 4],
		// 	indexFinger: [5, 6, 7, 8],
		// 	middleFinger: [9, 10, 11, 12],
		// 	ringFinger: [13, 14, 15, 16],
		// 	pinky: [17, 18, 19, 20],
		// 	palmBase: [0]

		for (let i = 0; i < l; i++) {
			let sphere = this._objects[i];
			sphere.position.x = (points[i].x - width * 0.5) / 6000;
			sphere.position.y = ((points[i].y - height * 0.5) / 6000) * -1;
			sphere.position.z = (clamp(points[i].z, -0.03, 0.03) / 10) * -1; //points[i][2];//-0.0001; //points[i][2] * 0.01; //clamp(points[i][2], -0.1, 0.1);//-0.0001; //points[i][2];
		}

		this.updateLinesNew();
	};

	public updatePointsAnimated = (points, width, height, ratio, imageWidth, imageHeight, extra) => {
		this._originalPoints = points;
		this._userPoints = [...points];

		let l = points.length;

		for (let i = 0; i < l; i++) {
			let sphere = this._objects[i];
			var newX = (points[i].x - imageWidth * 0.5) / 3300;
			var newY = ((points[i].y - imageHeight * 0.5) / 3300) * -1;

			if (ratio < 1) {
				newX = (points[i].x - imageWidth * 0.5) / 3300;
				newY = ((points[i].y - imageHeight * 0.5) / 3300) * -1;

				newX = newX / extra;
				newY = newY / extra;
			} else {
				newX = newX / extra;
				newY = newY / extra;
			}

			var newZ = (clamp(points[i].z, -0.03, 0.03) / 10) * -1; //points[i][2];//-0.0001; //points[i][2] * 0.01; //clamp(points[i][2], -0.1, 0.1);//-0.0001; //points[i][2];
			newZ = 0;

			gsap.to(sphere.position, { duration: 0.5, x: newX, y: newY, z: newZ, ease: 'power1.inOut', onUpdate: this.updateLinesNew });
		}
	};

	public toggle3D = state => {
		// let l = this._objects.length;
		let speed = 0.5;

		// for (let i = 0; i < l; i++) {
		// 	gsap.to(this._objects[i].position, { duration: speed, z: state ? this._userPoints[i][2] * 0.001 : -0.0001, ease: 'power2.inOut' });
		// }

		gsap.to(this, { duration: speed, onUpdate: this.updateLines, ease: 'power2.inOut' });

		///	this._dragControls.setRestriction({ axis: 'x', active: false, min: -Infinity, max: Infinity, disabled: state });
		//	this._dragControls.setRestriction({ axis: 'y', active: false, min: -Infinity, max: Infinity, disabled: state });
		// updateLines
	};

	private updateLinesNew = () => {
		this.drawLinesNew(
			[this._objects[0].position.clone(), this._objects[1].position.clone(), this._objects[2].position.clone(), this._objects[3].position.clone(), this._objects[4].position.clone()],
			0
		);

		this.drawLinesNew(
			[this._objects[0].position.clone(), this._objects[5].position.clone(), this._objects[6].position.clone(), this._objects[7].position.clone(), this._objects[8].position.clone()],
			1
		);

		this.drawLinesNew(
			[this._objects[0].position.clone(), this._objects[9].position.clone(), this._objects[10].position.clone(), this._objects[11].position.clone(), this._objects[12].position.clone()],
			2
		);

		this.drawLinesNew(
			[this._objects[0].position.clone(), this._objects[13].position.clone(), this._objects[14].position.clone(), this._objects[15].position.clone(), this._objects[16].position.clone()],
			3
		);

		this.drawLinesNew(
			[this._objects[0].position.clone(), this._objects[17].position.clone(), this._objects[18].position.clone(), this._objects[19].position.clone(), this._objects[20].position.clone()],
			4
		);
	};

	private updateLines = () => {
		this.drawLines(
			[this._objects[0].position.clone(), this._objects[1].position.clone(), this._objects[2].position.clone(), this._objects[3].position.clone(), this._objects[4].position.clone()],
			0
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[5].position.clone(), this._objects[6].position.clone(), this._objects[7].position.clone(), this._objects[8].position.clone()],
			1
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[9].position.clone(), this._objects[10].position.clone(), this._objects[11].position.clone(), this._objects[12].position.clone()],
			2
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[13].position.clone(), this._objects[14].position.clone(), this._objects[15].position.clone(), this._objects[16].position.clone()],
			3
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[17].position.clone(), this._objects[18].position.clone(), this._objects[19].position.clone(), this._objects[20].position.clone()],
			4
		);
	};

	private drawLines = (points: Array<THREE.Vector3>, id: number) => {
		if (this._lineMeshes[id]) {
			this.remove(this._lineMeshes[id]);
			this._lineMeshes[id] = null;
		}
		let geometry = new THREE.Geometry();

		let l = points.length;
		for (let i = 0; i < l; i++) {
			geometry.vertices.push(points[i].clone());
		}

		let line = new MeshLine();
		line.setGeometry(geometry);

		this._lineMeshes[id] = new THREE.Mesh(line.geometry, this._lineMaterials[id]);
		this._mainGroup.add(this._lineMeshes[id]);

		this._lineGeometryArray.push(this._lineMeshes[id]);
	};

	private drawLinesNew = (points: Array<THREE.Vector3>, id: number) => {
		if (this._lineMeshes[id]) {
			this._mainGroup.remove(this._lineMeshes[id]);
			this._lineMeshes[id] = null;
		}
		let geometry = new THREE.Geometry();

		let l = points.length;
		for (let i = 0; i < l; i++) {
			geometry.vertices.push(points[i].clone());
		}

		let line = new MeshLine();
		line.setGeometry(geometry);

		this._lineMeshes[id] = new THREE.Mesh(line.geometry, this._lineMaterials[id]);
		this._mainGroup.add(this._lineMeshes[id]);

		//	this._lineGeometryArray.push(this._lineMeshes[id]);
	};

	private onHoverOver = e => {
		let id = this.getFingerId(e.object.name);

		let color = new THREE.Color(this._highlightColor);

		if (id !== 999) {
			gsap.to(this._lineMaterials[id].color, { duration: 0.2, r: color.r, g: color.g, b: color.b, ease: 'Quad.easeOut', overwrite: true });
		} else {
			gsap.to(this._lineMaterials[0].color, { duration: 0.2, r: color.r, g: color.g, b: color.b, ease: 'Quad.easeOut', overwrite: true });
			gsap.to(this._lineMaterials[1].color, { duration: 0.2, r: color.r, g: color.g, b: color.b, ease: 'Quad.easeOut', overwrite: true });
			gsap.to(this._lineMaterials[2].color, { duration: 0.2, r: color.r, g: color.g, b: color.b, ease: 'Quad.easeOut', overwrite: true });
			gsap.to(this._lineMaterials[3].color, { duration: 0.2, r: color.r, g: color.g, b: color.b, ease: 'Quad.easeOut', overwrite: true });
			gsap.to(this._lineMaterials[4].color, { duration: 0.2, r: color.r, g: color.g, b: color.b, ease: 'Quad.easeOut', overwrite: true });
		}
	};

	private onHoverOut = e => {
		let id = this.getFingerId(e.object.name);

		let color;

		if (id !== 999) {
			color = new THREE.Color(this._fingerColors[id]);
			gsap.to(this._lineMaterials[id].color, { duration: 0.1, r: color.r, g: color.g, b: color.b, ease: 'none', overwrite: true });
		} else {
			color = new THREE.Color(this._fingerColors[0]);
			gsap.to(this._lineMaterials[0].color, { duration: 0.1, r: color.r, g: color.g, b: color.b, ease: 'none', overwrite: true });
			color = new THREE.Color(this._fingerColors[1]);
			gsap.to(this._lineMaterials[1].color, { duration: 0.1, r: color.r, g: color.g, b: color.b, ease: 'none', overwrite: true });
			color = new THREE.Color(this._fingerColors[2]);
			gsap.to(this._lineMaterials[2].color, { duration: 0.1, r: color.r, g: color.g, b: color.b, ease: 'none', overwrite: true });
			color = new THREE.Color(this._fingerColors[3]);
			gsap.to(this._lineMaterials[3].color, { duration: 0.1, r: color.r, g: color.g, b: color.b, ease: 'none', overwrite: true });
			color = new THREE.Color(this._fingerColors[4]);
			gsap.to(this._lineMaterials[4].color, { duration: 0.1, r: color.r, g: color.g, b: color.b, ease: 'none', overwrite: true });
		}
	};

	private onDragStart = e => {
		e.object.material.color.setHex(0xa84727);
		// console.log(e.object.name);
		gsap.to(e.object._tooltip, { duration: 0.3, opacity: 1 });

		this.updateTooltip(e);

		//this._datasetScene.toggleOrbitControls(false);
	};

	private onDrag = e => {
		this.updateLines();
		this.updateTooltip(e);

		// this._hand.updateBones(this._objects);
	};

	private updateTooltip = e => {
		let pos = e.object.position;

		let textureWidth = 0.2 * this._ratio;
		let textureHeight = 0.2;

		let x = -(textureWidth * 0.5) + pos.x;
		let y = textureHeight * 0.5 + pos.y;

		let xPos = ((x * -1) / textureWidth) * this._width - this._cropOffset.x;
		let yPos = this._height - (y / textureHeight) * this._height - this._cropOffset.y;
		let zPos = pos.z.toFixed(2); //

		e.object._tooltip.innerHTML = e.object.name + '<br> x' + Math.round(xPos) + ', y' + Math.round(yPos) + ', z' + zPos;
	};

	public updateOffset = (x, y) => {
		this._cropOffset.x = x;
		this._cropOffset.y = y;
	};

	private onDragEnd = e => {
		e.object.material.color.setHex(0xad4b2b);
		gsap.to(e.object._tooltip, { duration: 0.3, opacity: 0 });

		//this._datasetScene.toggleOrbitControls(true);

		this.updateUserPoints();
	};

	private updateUserPoints = () => {
		let l = this._userPoints.length;

		for (let i = 0; i < l; i++) {
			this._userPoints[i][2] = this._objects[i].position.z * 1000;
		}
	};

	private getFingerId = (name: string) => {
		if (name.indexOf('thumb') !== -1) {
			return 0;
		} else if (name.indexOf('indexFinger') !== -1) {
			return 1;
		} else if (name.indexOf('middleFinger') !== -1) {
			return 2;
		} else if (name.indexOf('ringFinger') !== -1) {
			return 3;
		} else if (name.indexOf('pinky') !== -1) {
			return 4;
		} else {
			return 999;
		}
	};

	public update = (scene, camera) => {
		//	this._tooltipRenderer.render(scene, camera);
	};

	public resize = () => {
		//	this._tooltipRenderer.setSize(window.innerWidth, window.innerHeight);
	};

	/**
	 * Get normalized positions within the current crop
	 */
	public getNormalizedPositions = () => {
		let positions = [];

		let l = this._objects.length;

		for (let i = 0; i < l; i++) {
			let pos = this._objects[i].position;

			let textureWidth = 0.2 * this._ratio;
			let textureHeight = 0.2;

			let x = -(textureWidth * 0.5) + pos.x;
			let y = textureHeight * 0.5 + pos.y;

			let xPos = ((x * -1) / textureWidth) * this._width - this._cropOffset.x;
			let yPos = this._height - (y / textureHeight) * this._height - this._cropOffset.y;
			let zPos = -0.0001; //pos.z;

			positions.push({ x: xPos, y: yPos, z: zPos });
		}

		return positions;
	};
}
