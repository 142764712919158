import * as THREE from 'three';
import { MeshLine, MeshLineMaterial } from 'threejs-meshline';
import { gsap } from 'gsap';
import { CSS2DObject, CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { clamp } from '../../../lib/com/hellomonday/utils/MathUtils';
import { CaptureView } from './CaptureView';

export class CaptureJoints extends THREE.Group {
	private _objects = [];
	private _video;
	private _ratio: number;

	private _lineMeshes = [];
	private _lineMaterial;

	private _textureOffset = { value: 1 };

	private _lineMaterials = [];

	private _tooltipRenderer: CSS2DRenderer;
	private _element;

	private _fingerColors: Array<number> = [0xe9a99b, 0xe99ba0, 0xe99bb1, 0xd796d4, 0x8a82b7];
	private _highlightColor: number = 0xffa800;

	private _cropOffset = { x: 0, y: 0 };

	private _originalPoints;
	private _userPoints;

	// private _hand;

	private _captureView: CaptureView;

	constructor(video, ratio, camera, renderer, element, main: CaptureView) {
		//, hand) {
		super();

		this._captureView = main;

		// this._hand = hand;

		this._video = video;
		this._ratio = ratio;

		this._element = element;

		this._tooltipRenderer = new CSS2DRenderer();
		this._tooltipRenderer.setSize(window.innerWidth, window.innerHeight);
		this._tooltipRenderer.domElement.classList.add('tooltipRenderer');
		element.appendChild(this._tooltipRenderer.domElement);

		// let texture = new THREE.CanvasTexture(this.generateTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping);

		// this._lineMaterial = new MeshLineMaterial({
		// 	// color: 0xc9718a,
		// 	color: true,
		// 	// transparent: true,
		// 	lineWidth: 0.0015,
		// 	useMap: true,
		// 	map: texture,
		// 	dashArray: 0.1, dashRatio: 0.1,
		// 	//transparent: true,opacity: 0.75
		// }); //  dashArray: 0.1, dashRatio: 0.1,

		for (let i = 0; i < 5; i++) {
			this._lineMaterials.push(
				new MeshLineMaterial({
					color: new THREE.Color(this._fingerColors[i]),
					// color: true,
					// transparent: true,
					lineWidth: 0.0015
					// useMap: true,
					// map: texture.clone(),
					// dashArray: 0.1, dashRatio: 0.1,
					//transparent: true,opacity: 0.75
				})
			);
		}

		// gsap.to(this._textureOffset, {duration: 5, value: 2, repeat: -1, yoyo: true, onUpdate: this.test})
		// gsap.to(this._lineMaterial.map.offset, {duration: 5, x: 10, repeat: -1, yoyo: true, onUpdate: this.test});
		// gsap.to(this._lineMaterial, {duration: 5, dashOffset: 1, repeat: -1, ease: 'none'});

		// this.addGui();
	}

	// private addGui = () => {
	// 	let top = Globals.GUI.addFolder('Hand');
	//
	// 	let textureOffset = top
	// 		.add(this._textureOffset, 'value')
	// 		.min(-1)
	// 		.max(10)
	// 		.step(0.01)
	// 		.name('TextureOffset')
	// 		.listen();
	// 	textureOffset.onChange(this.test);
	//
	// 	top.open();
	// };

	private test = material => {
		// this._lineMaterial.map.offset.x = this._textureOffset.value;
		// this._lineMaterial.map.needsUpdate = true;

		// this._lineMaterial.map.repeat.x = this._textureOffset.value;
		// this._lineMaterial.map.repeat.y = this._textureOffset.value;
		// this._lineMaterial.map.offset.x = ( 300 / 100 ) * this._lineMaterial.map.repeat.x;
		// this._lineMaterial.map.offset.y = ( 400 / 100 ) * this._lineMaterial.map.repeat.y;

		this._lineMaterial.repeat.x = this._textureOffset.value;
		this._lineMaterial.repeat.y = this._textureOffset.value;
	};

	private generateTexture() {
		let size = 256;

		let canvas = document.createElement('canvas');
		canvas.width = 512; //size;
		canvas.height = 256; //size;

		let context = canvas.getContext('2d');

		let gradient = context.createLinearGradient(0, 0, 512, 0);
		gradient.addColorStop(0, '#0000FF');
		gradient.addColorStop(1, '#FF0080');
		context.fillStyle = gradient;
		context.fillRect(0, 0, 512, 256);

		// canvas.style.position = 'absolute';
		// canvas.style.top = '300px';
		// canvas.style.left = '0px';
		// canvas.style.zIndex = '10000';
		// document.body.appendChild(canvas);

		return canvas;
	}
	public reset = () => {
		let l = this._lineMeshes.length;

		for (let i = 0; i < l; i++) {
			this.remove(this._lineMeshes[i]);
			this._lineMeshes[i] = null;
		}

		l = this._objects.length;

		for (let i = 0; i < l; i++) {
			this.remove(this._objects[i]);
			this._objects[i] = null;
		}

		this._objects = [];
	};

	public updatePoints = points => {
		this._originalPoints = points;
		this._userPoints = [...points];

		let textureWidth = 0.2 * this._ratio;
		let textureHeight = 0.2;

		let l = points.length;

		//  thumb: [1, 2, 3, 4],
		// 	indexFinger: [5, 6, 7, 8],
		// 	middleFinger: [9, 10, 11, 12],
		// 	ringFinger: [13, 14, 15, 16],
		// 	pinky: [17, 18, 19, 20],
		// 	palmBase: [0]

		for (let i = 0; i < l; i++) {
			let geometry = new THREE.SphereGeometry(i === 0 ? 0.004 : 0.002, 32, 32);
			let material = new THREE.MeshBasicMaterial({ color: 0xad4b2b });
			let sphere = new THREE.Mesh(geometry, material);
			//@ts-ignore
			sphere._id = i;
			sphere.position.x = -(textureWidth * 0.5) + textureWidth * (points[i][0] / this._video.videoWidth);
			sphere.position.y = textureHeight * 0.5 - textureHeight * (points[i][1] / this._video.videoHeight);
			sphere.position.z = clamp(points[i][2], -0.01, 0.01); //points[i][2];//-0.0001; //points[i][2] * 0.01; //clamp(points[i][2], -0.1, 0.1);//-0.0001; //points[i][2];

			sphere.name =
				(i === 0 ? 'wrist' : i < 5 ? 'thumb' : i > 4 && i < 9 ? 'indexFinger' : i > 8 && i < 13 ? 'middleFinger' : i > 12 && i < 17 ? 'ringFinger' : 'pinky') +
				(i === 0 ? '' : ': [' + (((i - 1) % 4) + 1) + ']');

			let tooltipDiv = document.createElement('div');
			tooltipDiv.className = 'tooltip';
			// tooltipDiv.textContent = 'x: 0, y: 0, z: 0';
			let tooltipObject = new CSS2DObject(tooltipDiv);
			tooltipObject.position.y = 0.005;
			sphere.add(tooltipObject);

			// @ts-ignore
			sphere._tooltip = tooltipDiv;

			// @ts-ignore
			sphere._tooltipObject = tooltipObject;

			this.add(sphere);
			this._objects.push(sphere);
		}

		this.updateLines();
	};

	public toggle3D = state => {
		// let l = this._objects.length;
		let speed = 0.5;

		// for (let i = 0; i < l; i++) {
		// 	gsap.to(this._objects[i].position, { duration: speed, z: state ? this._userPoints[i][2] * 0.001 : -0.0001, ease: 'power2.inOut' });
		// }

		gsap.to(this, { duration: speed, onUpdate: this.updateLines, ease: 'power2.inOut' });
	};

	private updateLines = () => {
		this.drawLines(
			[this._objects[0].position.clone(), this._objects[1].position.clone(), this._objects[2].position.clone(), this._objects[3].position.clone(), this._objects[4].position.clone()],
			0
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[5].position.clone(), this._objects[6].position.clone(), this._objects[7].position.clone(), this._objects[8].position.clone()],
			1
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[9].position.clone(), this._objects[10].position.clone(), this._objects[11].position.clone(), this._objects[12].position.clone()],
			2
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[13].position.clone(), this._objects[14].position.clone(), this._objects[15].position.clone(), this._objects[16].position.clone()],
			3
		);

		this.drawLines(
			[this._objects[0].position.clone(), this._objects[17].position.clone(), this._objects[18].position.clone(), this._objects[19].position.clone(), this._objects[20].position.clone()],
			4
		);
	};

	private drawLines = (points: Array<THREE.Vector3>, id: number) => {
		if (this._lineMeshes[id]) {
			this.remove(this._lineMeshes[id]);
			this._lineMeshes[id] = null;
		}
		let geometry = new THREE.Geometry();

		let l = points.length;
		for (let i = 0; i < l; i++) {
			geometry.vertices.push(points[i].clone());
		}

		let line = new MeshLine();
		line.setGeometry(geometry);

		this._lineMeshes[id] = new THREE.Mesh(line.geometry, this._lineMaterials[id]);
		this.add(this._lineMeshes[id]);
	};

	public updateOffset = (x, y) => {
		this._cropOffset.x = x;
		this._cropOffset.y = y;
	};

	private updateUserPoints = () => {
		let l = this._userPoints.length;

		for (let i = 0; i < l; i++) {
			this._userPoints[i][2] = this._objects[i].position.z * 1000;
		}
	};

	private getFingerId = (name: string) => {
		if (name.indexOf('thumb') !== -1) {
			return 0;
		} else if (name.indexOf('indexFinger') !== -1) {
			return 1;
		} else if (name.indexOf('middleFinger') !== -1) {
			return 2;
		} else if (name.indexOf('ringFinger') !== -1) {
			return 3;
		} else if (name.indexOf('pinky') !== -1) {
			return 4;
		} else {
			return 999;
		}
	};

	public update = (scene, camera) => {
		this._tooltipRenderer.render(scene, camera);
	};

	public resize = () => {
		this._tooltipRenderer.setSize(window.innerWidth, window.innerHeight);
	};

	/**
	 * Get normalized positions within the current crop
	 */
	public getNormalizedPositions = () => {
		let positions = [];

		let l = this._objects.length;

		for (let i = 0; i < l; i++) {
			let pos = this._objects[i].position;

			let textureWidth = 0.2 * this._ratio;
			let textureHeight = 0.2;

			let x = -(textureWidth * 0.5) + pos.x;
			let y = textureHeight * 0.5 + pos.y;

			let xPos = ((x * -1) / textureWidth) * this._video.videoWidth - this._cropOffset.x;
			let yPos = this._video.videoHeight - (y / textureHeight) * this._video.videoHeight - this._cropOffset.y;
			let zPos = -0.0001; //pos.z;

			positions.push({ x: xPos, y: yPos, z: zPos });
		}

		return positions;
	};
}
