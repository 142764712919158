import { CameraFeed } from '../camera/CameraFeed';
import { gsap } from 'gsap';
import * as THREE from 'three';
import { Globals } from '../data/Globals';
import { Gesture } from '../data/Gesture';
import { HandposeHandler } from '../utils/HandposeHandler';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'threejs-meshline';
import { DragControls } from '../three/controls/DragControls';
import { HandJoints } from '../three/ui/HandJoints';
import { clamp } from 'gsap/gsap-core';

//@ts-ignore
import * as fragment from '../shaders/TestFragment.glsl';

//@ts-ignore
import * as vertex from '../shaders/TestVertex.glsl';
import { EdgeShaderMaterial } from '../shaders/EdgeShaderMaterial';
import { Notifications } from '../ui/Notifications';
import { ImageUtils } from '../../lib/com/hellomonday/utils/ImageUtils';
import { Hand } from '../three/ui/Hand';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { MediaPipeClient } from '../utils/MediaPipeClient';
import { Texture } from 'three';
import { LoadTextureForPlane } from './LoadTextureForPlane';
import ScrollTrigger from 'gsap/ScrollTrigger';

export class HandObjectPool {
	private _planes = [];
	private _mainGroup = new THREE.Group();

	private _poolSize = 200;

	private _active: boolean = false;

	private INTERSECTED;

	private _groupThatMovesOnZ: THREE.Group;

	private _numberOfTexturesSwitched = 0;

	private _previousGroupPositionZ = -1;

	constructor(groupThatMovesOnZ: THREE.Group) {
		this._groupThatMovesOnZ = groupThatMovesOnZ;
		this.createObjectPool();
	}

	private createObjectPool = () => {
		const geometry = new THREE.PlaneGeometry(0.06, 0.06, 1, 1);

		for (let i = 0; i < this._poolSize; i++) {
			var material = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
			const object = new THREE.Mesh(geometry, material);
			object.userData.countToLoad = 0;

			this.randomizeColor(object);
			this.randomizePosition(object, true, true);

			this._planes.push(object);
			this._mainGroup.add(object);
		}
	};

	private randomizePosition = (object: THREE.Mesh, inFrontOfTheCamera: boolean, firstRun: boolean) => {
		object.position.x = Math.random() * 0.8 - 0.4;
		object.position.y = Math.random() * 0.8 - 0.4;

		var groupZPosition = this._groupThatMovesOnZ.position.z;

		if (inFrontOfTheCamera) {
			object.position.z = Math.random() * -1000 - 0 - groupZPosition;
			//	object.position.z = -500 - groupZPosition;
		} else {
			object.position.z = Math.random() * 400 - groupZPosition; //Math.random() * -100;
		}

		/*if (Math.floor(Math.random() * 2) === 0) {
			object.userData._staticPosition = true;
			object.rotation.x = 0;
			object.rotation.y = 0;
			object.rotation.z = 0;
		} else {*/
		object.rotation.x = (Math.random() * 0.3 - 0.3) * Math.PI + Math.PI / 1;
		object.rotation.y = (Math.random() * 0.3 - 0.3) * Math.PI + Math.PI / 1;
		object.rotation.z = (Math.random() * 0.3 - 0.3) * Math.PI;
		object.userData._staticPosition = false;
		//}

		object.userData._storePosition = object.position.clone();
		object.userData._storeRotation = object.rotation.clone();

		/*	gsap.to(object.position, {
				duration: 1.5,
				delay: Math.random() * 0.5,
				repeat: -1,
				yoyo: true,
				ease: "power1.inOut",
				y: object.position.x + Math.random() * 0.2 - 0.2,
				x:object.position.x + Math.random() * 0.2 - 0.2
			});*/

		// Only load textures for planes of every 10ths image? (ca)
		//if (Math.floor(Math.random() * 10) === 0) {

		if (object.userData.countToLoad === 1 || firstRun === true) {
			this.loadTexture(object);
		}

		object.userData.countToLoad = 0;
		gsap.killTweensOf(object.userData);
		gsap.to(object.userData, { countToLoad: 1, duration: 1 });

		//}

		//	new LoadTextureForPlane(object);
		//if (firstRun) {

		//}
	};

	private loadTexture = (object: THREE.Mesh) => {
		this._numberOfTexturesSwitched = this._numberOfTexturesSwitched + 1;
		//console.log('loadTexture : ' + this._numberOfTexturesSwitched);

		if (this._numberOfTexturesSwitched > 100) {
			if (this._numberOfTexturesSwitched % 100 === 0) {
				console.log('Load');
				Globals.DATA_MANAGER.getXNumberOfRandomHands();
			}
		}
		new LoadTextureForPlane(object);
	};

	private randomizeColor = object => {
		var setColor = Globals.COLORS.white;
		var random = Math.floor(Math.random() * 3);
		if (random === 0) {
			setColor = Globals.COLORS.red;
		} else if (random === 1) {
			setColor = Globals.COLORS.blue;
		} else if (random === 2) {
			setColor = 0x666666;
		}
		object.material.color.setHex(setColor);
	};

	public onRender = () => {
		var groupZPosition = this._groupThatMovesOnZ.position.z;
		//console.log('groupZPosition : ' + groupZPosition);

		//if (this._active === true) {

		if (this._previousGroupPositionZ !== groupZPosition) {
			for (var i = 0; i < this._poolSize; i++) {
				var plane = this._planes[i];
				var calculateCombinedZ = plane.position.z + groupZPosition;
				//console.log(plane.position.z);
				if (i === 0) {
					//	console.log('before plane.position.z : ' + plane.position.z);
					//	console.log('calculateCombinedZ: ' + calculateCombinedZ);
				}

				// FIXME - make so it does not intersect currently intersecting plane
				if (Globals.CLICKED_HAND !== plane && plane.userData._staticPosition !== true) {
					plane.rotation.y = plane.userData._storeRotation.y + calculateCombinedZ / 30;
					plane.rotation.z = plane.userData._storeRotation.z + calculateCombinedZ / 100;
				}

				// Scroll Direction 1 = Down
				if (Globals.SCROLL_DIRECTION === 1 && calculateCombinedZ > 0) {
					//gsap.killTweensOf(plane.position);
					this.randomizePosition(plane, true, false);
					if (i === 0) {
						//	console.log('Direction 1 — plane.position.z : ' + plane.position.z);
					}
				} else if (Globals.SCROLL_DIRECTION === -1 && calculateCombinedZ < -200) {
					// Scroll Direction -1 = UP
					//gsap.killTweensOf(plane.position);
					this.randomizePosition(plane, false, false);
					if (i === 0) {
						//	console.log('Direction -1 — plane.position.z : ' + plane.position.z);
					}
				}
			}
		}
		//}

		this._previousGroupPositionZ = groupZPosition;
	};

	public mouseClick = (raycaster, scene) => {
		if (Globals.HAND_SCENE_ACTIVE === true) {
			const intersects = raycaster.intersectObjects(this._mainGroup.children, false);
			//console.log(intersects);

			if (intersects.length > 0) {
				if (this.INTERSECTED != intersects[0].object) {
					if (this.INTERSECTED) {
						//	this.INTERSECTED.material.color.setHex(0xff0000);
					}

					this.INTERSECTED = intersects[0].object;
					//	console.log(this.INTERSECTED.position.z);
					//	console.log(this.INTERSECTED.position);
				}
			} else {
				if (this.INTERSECTED) {
					//	console.log(this.INTERSECTED.position);
				}
				this.INTERSECTED = null;
			}

			if (this.INTERSECTED) {
				//	console.log(this.INTERSECTED.position.z);

				gsap.to(this.INTERSECTED.rotation, { x: 0, y: 0, z: 0, ease: 'power1.inOut', duration: 0.5 });
				var groupZPosition = this._groupThatMovesOnZ.position.z;
				//	var calculateCombinedZ = this.INTERSECTED.position.z + groupZPosition;
				gsap.to(this.INTERSECTED.position, { z: groupZPosition * -1 - 15, x: 0, y: 0, ease: 'power1.inOut', duration: 0.5 });

				/*gsap.to(this._mainGroup.position, {
					x: this.INTERSECTED.position.x * -1,
					y: this.INTERSECTED.position.y * -1,

					ease: 'power1.inOut',
					duration: 0.5
				});*/
				//z: this.INTERSECTED.position.z,

				var getData = this.INTERSECTED.userData.data;
				//	console.log(getData);

				if (getData) {
					var pointData = [
						getData.key_point_0,
						getData.key_point_1,
						getData.key_point_2,
						getData.key_point_3,
						getData.key_point_4,
						getData.key_point_5,
						getData.key_point_6,
						getData.key_point_7,
						getData.key_point_8,
						getData.key_point_9,
						getData.key_point_10,
						getData.key_point_11,
						getData.key_point_12,
						getData.key_point_13,
						getData.key_point_14,
						getData.key_point_15,
						getData.key_point_16,
						getData.key_point_17,
						getData.key_point_18,
						getData.key_point_19,
						getData.key_point_20
					];
				}
				//	console.log(pointData);

				var scaleX = this.INTERSECTED.scale.x;
				if (scaleX < 0) {
					//	scaleX = scaleX * -1;
				}

				var scaleY = this.INTERSECTED.scale.y;
				if (scaleY < 0) {
					//	scaleY = scaleY * -1;
				}

				var ratio = this.INTERSECTED.scale.y / this.INTERSECTED.scale.x;

				/*	console.log(ratio);

				console.log('scaleX : ' + scaleX);
				console.log('scaleY : ' + scaleY);

				console.log('this.INTERSECTED.userData._storeWidth : ' + this.INTERSECTED.userData._storeWidth);
				console.log('this.INTERSECTED.userData._storeHeight : ' + this.INTERSECTED.userData._storeHeight);
				console.log(this.INTERSECTED);
				console.log(this.INTERSECTED.material);*/

				//Globals.HAND_JOINTS.setNewWidthAndHeight(1000, this.INTERSECTED.scale.y * 1000, 1);

				//	console.log('Ratio: ' + this.INTERSECTED.userData._ratio);
				if (this.INTERSECTED.userData._storeHeight > this.INTERSECTED.userData._storeWidth) {
					//		console.log('Higher height');
					//	Globals.HAND_JOINTS.updatePointsAnimated(pointData, this.INTERSECTED.userData._storeWidth, this.INTERSECTED.userData._storeHeight, this.INTERSECTED.userData._ratio);
				} else {
					//	Globals.HAND_JOINTS.updatePointsAnimated(pointData, this.INTERSECTED.userData._storeWidth * this.INTERSECTED.scale.y, this.INTERSECTED.userData._storeHeight * this.INTERSECTED.scale.y, this.INTERSECTED.userData._ratio);
				}

				var useHeight = this.INTERSECTED.userData._storeHeight;
				var useWidth = this.INTERSECTED.userData._storeWidth;

				var differenceToNormal = useHeight / 162;

				useHeight = useHeight / differenceToNormal;
				useWidth = useWidth / differenceToNormal;

				//	console.log('differenceToNormal : ' + differenceToNormal);

				Globals.HAND_JOINTS.updatePointsAnimated(
					pointData,
					useWidth,
					useHeight,
					this.INTERSECTED.userData._ratio,
					this.INTERSECTED.userData._storeWidth,
					this.INTERSECTED.userData._storeHeight,
					differenceToNormal
				);

				//this._scrollTrigger.disable();
				//document.body.style.overflowY = 'visible';

				//		console.log(' ---  Globals.PREVIOUSLY_CLICKED_HAND : ' + Globals.PREVIOUSLY_CLICKED_HAND);

				Globals.CLICKED_HAND = this.INTERSECTED;

				Globals.HAND_SCENE.closeHandView(this.INTERSECTED);
				Globals.PREVIOUSLY_CLICKED_HAND = this.INTERSECTED;
				Globals.HAND_SCENE.openHandView(this.INTERSECTED);
			}
		}
	};

	get mainGroup() {
		return this._mainGroup;
	}

	set turnOn(value: boolean) {
		this._active = value;
	}
}
