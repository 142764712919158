import { FolderApi, Pane } from 'tweakpane';

export class WorldPane extends Pane {
	public readonly views: FolderApi;
	private static instance: WorldPane;

	constructor() {
		super();

		//@ts-ignore
		this.containerElem_.style.zIndex = 100;
		//@ts-ignore
		this.containerElem_.style.width = '350px';
		//@ts-ignore
		this.containerElem_.style.position = 'fixed';

		//@ts-ignore
		this.containerElem_.style.display = 'none';
	}

	public static getInstance(): WorldPane {
		if (!WorldPane.instance) {
			WorldPane.instance = new WorldPane();
		}

		return WorldPane.instance;
	}

	public static kill() {
		WorldPane.instance.dispose();
		WorldPane.instance = undefined;
	}
}
