import { AbstractView } from '../core/AbstractView';

export class MainView extends AbstractView {
	private _element: HTMLElement;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		this._element = element;
	}

	public resize = () => {
		super.resize();
	};

	kill() {
		super.kill();
	}
}
