import { Gesture } from '../data/Gesture';

import { getAuth, signInAnonymously } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { doc, increment, updateDoc, addDoc, getDoc, getDocs, collection, getFirestore, query, where, limit, orderBy, startAt, getCountFromServer } from '@firebase/firestore';

import { Globals } from '../data/Globals';

export class DataManager {
	private _storageRef;
	private _db;
	private _callback;

	private _allData = [];

	constructor(callback) {
		this._callback = callback;
		let firebaseConfig = {
			apiKey: 'AIzaSyDQPJOL-2v9xeKQ2aesie-iFC0bIGRR2Rk',
			authDomain: 'helping-hands-e0c3c.firebaseapp.com',
			databaseURL: 'https://helping-hands-e0c3c.firebaseio.com',
			projectId: 'helping-hands-e0c3c',
			storageBucket: 'helping-hands-e0c3c.appspot.com',
			messagingSenderId: '598001684143',
			appId: '1:598001684143:web:2b8581961a56d2d2698e5a',
			measurementId: 'G-SFB57FWWNR'
		};

		// Initialize Firebase
		initializeApp(firebaseConfig);

		const app = initializeApp(firebaseConfig);
		this._db = getFirestore(app);

		const auth = getAuth(app);
		signInAnonymously(auth)
			.then(() => {
				//	console.log('signed in');
				this._callback();
			})
			.catch(e => {
				console.log(e);
				console.log(e.code + ' : ' + e.message);
			});
	}

	public async getNumberOfGestures() {
		/*	const stats = doc(this._db, 'global', '--stats--');
		const statsDoc = await getDoc(stats);
		if (statsDoc.exists()) {
			const statsData = statsDoc.data();
			return statsData.gestureCount;
		} else {
			return 0;
		}*/

		/*this._db.collection("gestures")
			.where("status", "==", "approved")
			.get()
			.then((querySnapshot) => {
				const count = querySnapshot.size;
				console.log(`Number of documents with status equal toapproved: ` + count);
			})
			.catch((error) => {
				console.log(`Error getting documents: ${error}`);
			});
*/
		const q = query(collection(this._db, 'gestures'), where('status', '!=', 'in-review'));
		const querySnapshot = await getCountFromServer(q);

		//console.log('count: ', querySnapshot.data().count);

		Globals.TOTAL_GESTURE_COUNT = querySnapshot.data().count;

		//console.log('count: ', querySnapshot.data().count);

		//console.log('Globals.TOTAL_GESTURE_COUNT : ' + Globals.TOTAL_GESTURE_COUNT);

		return querySnapshot.data().count;
	}

	public async getTotalGestures() {
		const q = query(collection(this._db, 'gestures'), where('status', '!=', 'in-review'));
		const querySnapshot = await getCountFromServer(q);
		console.log('Number of IDs: ', querySnapshot.data().count);

		return querySnapshot.data().count;
	}

	public async storeGesture(gestureData) {
		// // Update count
		// const stats = doc(this._db, 'global', '--stats--');
		// await updateDoc(stats, {gestureCount: increment(1)})
		// 	.then(e => {
		// 		// console.log(e);
		// 	})
		// 	.catch(e => {
		// 		console.log(e);
		// 	});
		//
		// const statsDoc = await getDoc(stats);

		// // Add id (count)
		// if (statsDoc.exists()) {
		// 	const statsData = statsDoc.data();
		let id = (await this.getTotalGestures()) + 1;
		console.log('id: ' + id);
		gestureData.id = id;
		Globals.currentSnapshotID = id;

		console.log(gestureData);

		// Add gesture
		await addDoc(collection(this._db, 'gestures'), gestureData).catch(e => {
			console.log(e);
		});
	}

	public getAllData = () => {
		return this._allData;
	};

	public async getAllGestures(callback?: Function) {
		// Get all gestures (no matter their status)
		/*	const querySnapshot = await getDocs(collection(this._db, "gestures"));
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			console.log(doc.id, " => ", doc.data());
		});*/

		// Get all approved gestures
		const q = query(collection(this._db, 'gestures'), where('status', '==', 'approved'));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(doc => {
			// doc.data() is never undefined for query doc snapshots
			//	console.log(doc.id, ' => ', doc.data());
		});
		return;
	}

	public async getXNumberOfRandomHands(callback?: Function) {
		// Get all gestures (no matter their status)
		/*	const querySnapshot = await getDocs(collection(this._db, "gestures"));
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				console.log(doc.id, " => ", doc.data());
			});*/

		/*	const coll = collection(this._db, "gestures");
		const snapshot = await getCountFromServer(coll);
		console.log('count: ', snapshot.data().count);*/

		// Get all approved gestures
		//, limit(10), startAt(5)

		//for (let i = 0; i < 10; i++) {
		//   var randNum =  Math.floor(Math.random() * 10) + 1;
		//   console.log(randNum)
		// }
		//const q = query(collection(this._db, 'gestures'), where('status', '==', 'approved'));

		var numberOfItemsToReturn = 20;
		var randNum = Math.floor(Math.random() * Globals.TOTAL_GESTURE_COUNT) - numberOfItemsToReturn;
		if (randNum < 0) {
			randNum = 0;
		}

		//console.log('Globals.TOTAL_GESTURE_COUNT: ' + Globals.TOTAL_GESTURE_COUNT);

		//console.log(randNum);

		const q = query(collection(this._db, 'gestures'), limit(20), where('status', '==', 'approved_keypoints_manually_adjusted'), where('random', '>', Math.random() * 10000000));
		//	const q2 = query(collection(this._db, 'gestures'), orderBy('random'), where('random','>', Math.random() * 1000000));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(doc => {
			// doc.data() is never undefined for query doc snapshots
			//	console.log(doc.id, " => ", doc.data());

			this._allData.push({ id: doc.id, data: doc.data() });
		});

		const q2 = query(collection(this._db, 'gestures'), limit(20), where('status', '==', 'approved'), where('random', '>', Math.random() * 10000000));
		//	const q2 = query(collection(this._db, 'gestures'), orderBy('random'), where('random','>', Math.random() * 1000000));

		const querySnapshot2 = await getDocs(q2);
		querySnapshot2.forEach(doc => {
			// doc.data() is never undefined for query doc snapshots
			//	console.log(doc.id, " => ", doc.data());

			this._allData.push({ id: doc.id, data: doc.data() });
		});

		//console.log(this._allData.length)

		//const collectionRef = this._db.collection("gestures");

		// Use where() to filter documents by status field
		//const query = collectionRef.where("status", "!=", "not-reviewed");

		/*	var numberOfItemsToReturn = 50;
		var randNum = Math.floor(Math.random() * (Globals.TOTAL_GESTURE_COUNT - numberOfItemsToReturn));
console.log(randNum)
		if (randNum < 0) {
			randNum = 0;
		}
		console.log(randNum)
		const q = query(collection(this._db, 'gestures'), where('status', '!=', 'not-reviewed'), limit(10), startAt(randNum));

		// Use orderBy() with a random value to order documents randomly
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(doc => {
			// doc.data() is never undefined for query doc snapshots
			//console.log(doc.id, ' => ', doc.data());
			this._allData.push({ id: doc.id, data: doc.data() });
		});*/
		if (callback) {
			callback();
		}
	}

	public async getRandomHand(callback?: Function) {
		/*
		var numberOfItemsToReturn = 1;
		var randNum = Math.floor(Math.random() * (Globals.TOTAL_GESTURE_COUNT - numberOfItemsToReturn));

		const q = query(collection(this._db, 'gestures'), limit(1));

		const querySnapshot = await getDoc(q);
		console.log(querySnapshot)
		querySnapshot.forEach(doc => {
			// doc.data() is never undefined for query doc snapshots
			console.log(doc.id, " => ", doc.data());

		//	this._allData.push({ id: doc.id, data: doc.data() });
			callback(doc.data());
		});*/

		var _length = this._allData.length;
		var getRandomHand = this._allData[Math.floor(Math.random() * _length)];

		return getRandomHand;
	}

	private uuidv4 = () => {
		//@ts-ignore
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
	};

	private generateUUID(): any {
		let d = new Date().getTime();
		let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function(c) {
			let r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
		});
		return uuid;
	}
}
