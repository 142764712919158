import { gsap } from 'gsap';

export class Notifications {
	private _element: HTMLDivElement;

	private _error: HTMLDivElement;
	private _consent: HTMLDivElement;

	private _notifications: NodeListOf<HTMLDivElement>;

	private _consentButton;
	private _consentCheckmark;
	private _userConsent: boolean = false;

	private _current;

	constructor(element: HTMLDivElement) {
		this._element = element;

		this._notifications = element.querySelectorAll('.notification');

		let l = this._notifications.length;

		for (let i = 0; i < l; i++) {
			gsap.set(this._notifications, { y: 20 });
		}

		this._error = element.querySelector('.error');
		this._consent = element.querySelector('.consent');

		this._consentButton = this._consent.querySelector('.inner');
		this._consentButton.addEventListener('click', this.onConsentClick);
		this._consentCheckmark = this._consentButton.querySelector('.checkmark');
		gsap.set(this._consentCheckmark, { scale: 0.5, transformOrigin: '50% 50%' });
	}

	private onConsentClick = () => {
		this._userConsent = !this._userConsent;
		gsap.to(this._consentCheckmark, { duration: 0.2, scale: this._userConsent ? 1 : 0.5, opacity: this._userConsent ? 1 : 0, transformOrigin: '50% 50%' });
	};

	public triggerNotification = (type: string) => {
		this.reset();

		if (type === 'error') {
			this._error.classList.add('active');
			gsap.killTweensOf(this._error);
			gsap.to(this._error, { duration: 0.3, y: 0, ease: 'back.out' });
			gsap.to(this._error, { duration: 0.3, opacity: 1 });

			this._current = this._error;
		} else if (type === 'consent') {
			this._consent.classList.add('active');
			gsap.killTweensOf(this._consent);
			gsap.to(this._consent, { duration: 0.3, y: 0, ease: 'back.out' });
			gsap.to(this._consent, { duration: 0.3, opacity: 1 });

			this._current = this._consent;
		}
	};

	public reset = () => {
		this._current = null;

		let l = this._notifications.length;

		for (let i = 0; i < l; i++) {
			gsap.to(this._notifications[i], { duration: 0.2, opacity: 0, onComplete: this.hideNotification, onCompleteParams: [this._notifications[i]] });
		}

		this._userConsent = false;
		gsap.to(this._consentCheckmark, { duration: 0.2, scale: 0.5, opacity: 0, transformOrigin: '50% 50%' });
	};

	public shakeCurrent = () => {
		if (this._current) {
			let tl = gsap.timeline({});

			tl.to(this._current, { duration: 0.1, x: -5 })
				.to(this._current, { duration: 0.1, x: 5 })
				.to(this._current, { duration: 0.1, x: -5 })
				.to(this._current, { duration: 0.1, x: 0 });
		}
	};

	private hideNotification = notification => {
		gsap.set(notification, { y: 20 });
		notification.classList.remove('active');
	};

	get userConsented() {
		return this._userConsent;
	}
}
