import { AbstractView } from '../core/AbstractView';
import { gsap } from 'gsap';
import { Globals } from '../../data/Globals';

export class SubpageView extends AbstractView {
	private _element: HTMLElement;
	private _closeButton: HTMLElement;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		Globals.HAND_SCENE.switchToGiveAHand();

		this._element = element;

		this._closeButton = element.querySelector('.closeButton');
		this._closeButton.addEventListener('click', this.onCloseClick);

		this.init();
	}

	private onCloseClick = () => {
		Globals.VIEW_MANAGER.setPath('');
	};

	private init = () => {
		this.resize();
	};

	public resize = () => {
		super.resize();
	};

	out() {
		gsap.to(this._closeButton, { x: 300, ease: 'power1.in', duration: 0.3 });
		gsap.to(this.element, 0.5, { delay: 0.3, opacity: 0, onComplete: this.outComplete, overwrite: true });
	}

	kill() {
		super.kill();
		Globals.HAND_SCENE.switchToMainScene();
	}
}
