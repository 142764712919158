import { detect } from 'detect-browser';

declare class DocumentTouch {}

import { ViewManager } from '../managers/ViewManager';
import { DataManager } from '../firebase/DataManager';
import { Notifications } from '../ui/Notifications';
import { HandScene } from '../3d/HandScene';
import { GiveAHandButton } from '../ui/GiveAHandButton';
import { SubMenu } from '../ui/SubMenu';
import { CenterCTA } from '../ui/CenterCTA';
import { HandJoints } from '../three/ui/HandJoints';

export class Globals {
	// DEBUG Variables
	public static DEBUG: boolean = false;

	public static SITE_WRAPPER: HTMLDivElement = document.getElementById('App') as HTMLDivElement;
	// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static IS_TOUCH_DEVICE: boolean = 'ontouchstart' in window || ((window as any).DocumentTouch && document instanceof DocumentTouch);
	public static IS_IE: boolean = false;

	private static DOCUMENT_TOUCH: DocumentTouch = (window as any).DocumentTouch;
	public static IS_MOBILE: boolean = false;
	public static IS_ANDROID: boolean = navigator.userAgent.toLowerCase().indexOf('android') > -1;
	public static IS_IOS: boolean = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

	public static DEBUG_AUTOPLAY: boolean = false;
	public static HAS_SCROLLED: boolean = false;

	// elements
	public static HAND_DATA_ELEMENT: HTMLDivElement;

	public static VIEW_MANAGER: ViewManager;

	public static HAND_SCENE: HandScene;
	public static HAND_SCENE_ACTIVE: boolean = true;

	public static PREVIOUSLY_CLICKED_HAND;
	public static CLICKED_HAND;

	public static MAIN_VIEW_CONTAINER: HTMLDivElement;

	public static TOTAL_GESTURE_COUNT: number = 0;

	public static HAND_JOINTS: HandJoints;

	public static SOUND_ON: boolean = true;

	public static GUI;

	public static DATA_MANAGER: DataManager;

	public static SCROLL_HEIGHT: number = 70000;

	public static browser = detect();

	public static notifications: Notifications;

	public static currentSnapshot: HTMLImageElement;
	public static currentSnapshotID: number;

	public static GIVE_A_HAND_BUTTON: GiveAHandButton;
	public static SUB_MENU: SubMenu;
	public static LOGO_TOP_LEFT: HTMLDivElement;
	public static CENTER_CTA: CenterCTA;

	public static LOADER_JSON;

	public static HAND_SCENE_MASTER_TIMELINE;
	public static MAIN_SCROLL_TRIGGER;

	public static COLORS = { white: 0xffffff, red: 0xf83600, blue: 0x3d5ed6 };

	public static SCROLL_DIRECTION = 1;
}
