import { gsap } from 'gsap';

export class SpriteAnimation {
	public container: HTMLDivElement;

	private _frameData: Array<any> = [];

	private _spriteSheet: HTMLImageElement;
	private _spriteFrame = { value: 0 };
	private _currentFrame: number = 0;

	private _speed: number;
	private _loop: boolean = false;
	private _playing: boolean = false;

	constructor(data, container: HTMLDivElement, speed: number) {
		this.container = container;
		this._spriteSheet = container.querySelector('img');
		this._speed = speed;

		Object.keys(data.frames).forEach(key => {
			this._frameData.push(data.frames[key]);
		});

		let frameId = 0;

		gsap.set(this.container, {
			width: this._frameData[frameId].spriteSourceSize.w,
			height: this._frameData[frameId].spriteSourceSize.h,
			overflow: 'hidden',
			position: 'absolute',
			top: 0,
			left: 0,
			pointerEvents: 'none'
		});

		gsap.set(this._spriteSheet, {
			x: -this._frameData[frameId].frame.x,
			y: -this._frameData[frameId].frame.y,
			position: 'absolute',
			top: 0,
			left: 0,
			pointerEvents: 'none'
		});

		// this.container.appendChild(this._spriteSheet);
	}

	public play = (callback: () => void = null) => {
		this._playing = true;

		gsap.killTweensOf(this._spriteSheet);
		gsap.killTweensOf(this);

		this._currentFrame = 0;
		this.updateFramePosition();

		gsap.to(this._spriteFrame, {
			duration: this._speed,
			value: this._frameData.length - 1,
			repeat: this._loop ? -1 : 0,
			roundProps: ['value'],
			onUpdate: this.updateFramePosition,
			// onRepeat: this.onRepeat,
			// onRepeatParams: [callback],
			// onComplete: this.onComplete,
			// onCompleteParams: [callback],
			ease: 'none',
			overwrite: true
		});

		// gsap.delayedCall(1, () => {
		// 	this._spriteFrame++;
		// 	this.updateFramePosition();
		// });
	};

	// private onRepeat = (callback: () => void = null) => {
	// 	if (callback) {
	// 		callback();
	// 	}
	// };

	private onComplete = (callback: () => void = null) => {
		this._playing = false;
		//
		// if (callback) {
		// 	callback();
		// }
	};

	public reversePlay = () => {
		this._playing = true;

		gsap.killTweensOf(this._spriteSheet);
		gsap.killTweensOf(this);

		this._currentFrame = this._frameData.length - 1;
		this.updateFramePosition();

		gsap.to(this, this._speed, {
			_spriteFrame: 0,
			roundProps: ['_spriteFrame'],
			onUpdate: this.updateFramePosition,
			ease: Linear.easeNone,
			overwrite: true
		});
	};

	public pause = () => {
		this._playing = false;

		gsap.killTweensOf(this._spriteSheet);
		gsap.killTweensOf(this);
	};

	public stop = () => {
		this._playing = false;

		this._spriteFrame.value = 0;
		gsap.killTweensOf(this._spriteSheet);
		gsap.killTweensOf(this);
		this.updateFramePosition();
	};

	private updateFramePosition = () => {
		this._currentFrame = Math.round(this._spriteFrame.value);

		gsap.set(this.container, {
			x: this._frameData[this._currentFrame].spriteSourceSize.x,
			y: this._frameData[this._currentFrame].spriteSourceSize.y,
			width: this._frameData[this._currentFrame].spriteSourceSize.w,
			height: this._frameData[this._currentFrame].spriteSourceSize.h
		});

		gsap.set(this._spriteSheet, {
			x: -this._frameData[this._currentFrame].frame.x,
			y: -this._frameData[this._currentFrame].frame.y
		});
	};

	set loop(value: boolean) {
		this._loop = value;
	}

	set speed(value: number) {
		this._speed = value;
	}
}
