import { gsap } from 'gsap';
import { Globals } from '../../../data/Globals';
import { CaptureView } from '../../CaptureView/CaptureView';

interface IStep {
	activeButtons: Array<number>;
	label: string;
	id: number;
}

export class Steps {
	private _element: HTMLDivElement;
	private _steps: NodeListOf<HTMLDivElement>;
	private _stepsContainer: HTMLDivElement;
	private _buttons: NodeListOf<HTMLDivElement>;
	private _currentStep: number = 0;

	private _buttonSnapshot: HTMLDivElement;
	private _buttonConfirm: HTMLDivElement;
	private _buttonBack: HTMLDivElement;
	private _buttonSubmit: HTMLDivElement;

	private _buttonRestart: HTMLDivElement;
	//private _buttonGallery: HTMLDivElement;

	private _consent: HTMLDivElement;

	private _captureView: CaptureView;

	private _titleStepText: Array<string> = ['Capture your hand gesture', 'Crop the entire hand inside the area', 'Consent and Submit', 'Thanks for helping'];

	private _title: HTMLDivElement;
	private _titleStep: HTMLDivElement;
	private _gestureID: HTMLDivElement;

	private _processing: boolean = false;

	// private _spinner: HTMLDivElement;

	constructor(element: HTMLDivElement, captureView: CaptureView) {
		this._element = element;
		this._captureView = captureView;
		this._gestureID = element.querySelector('.gestureID');
		this._titleStep = element.querySelector('.titleStep');
		this._title = element.querySelector('.title');
		this._stepsContainer = element.querySelector('.steps');
		this._steps = element.querySelectorAll('.step');
		this._buttons = element.querySelectorAll('.button');

		// this._spinner = element.querySelector('.spinner');

		this._buttonSnapshot = element.querySelector('.snapButton');
		this._buttonSnapshot.addEventListener('click', this._captureView.snapShot);

		this._buttonConfirm = element.querySelector('.confirm');
		this._buttonConfirm.addEventListener('click', this.onConfirm);

		this._buttonBack = element.querySelector('.back');
		this._buttonBack.addEventListener('click', this.onBackClick);

		this._buttonSubmit = element.querySelector('.submit');
		this._buttonSubmit.addEventListener('click', this.onSubmit);

		this._buttonRestart = element.querySelector('.restart');
		this._buttonRestart.addEventListener('click', this._captureView.reset);

		//this._buttonGallery = element.querySelector('.gallery');
		//this._buttonGallery.addEventListener('click', this.gotoGallery);

		this._consent = element.querySelector('.consent');
	}

	private gotoGallery = () => {
		console.log('gotoGallery');
	};

	public onSubmit = () => {
		if (this._currentStep === 2) {
			if (!Globals.notifications.userConsented) {
				Globals.notifications.shakeCurrent();
			} else {
				if (!this._processing) {
					this._captureView.saveGesture();
					this._processing = true;
					gsap.to(this._buttonSubmit, { opacity: 0.5, duration: 0.2 });

					gsap.to(this._captureView.loader, { opacity: 1, duration: 0.3 });
					// gsap.to(this._spinner, { opacity: 1, duration: 0.3 });
				}
			}
		}
	};

	public onConfirm = e => {
		if (this._currentStep === 1) {
			this._captureView.cropToBoundingBox();
		} else if (this._currentStep === 2) {
			this.setStage('keypoints3D');
		} else if (this._currentStep === 3) {
			this.setStage('review');
		} else if (this._currentStep === 4) {
			if (!Globals.notifications.userConsented) {
				Globals.notifications.shakeCurrent();
			} else {
				this._captureView.saveGesture();
			}
		}
	};

	public onBackClick = e => {
		if (this._currentStep === 1) {
			this._captureView.reset();
		} else if (this._currentStep === 2) {
			this._captureView.resetHandjoints();
			this.setStage('crop');
		}
		// else if (this._currentStep === 3) {
		// 	this.setStage('keypoints2D');
		// } else if (this._currentStep === 4) {
		// 	this.setStage('keypoints3D');
		// }
	};

	public setStage = (step: string) => {
		let activeButtons = [];

		this._gestureID.innerText = '';

		switch (step) {
			case 'snap':
				// gsap.to(this._spinner, { opacity: 0, duration: 0.3 });
				gsap.to(this._captureView.loader, { opacity: 0, duration: 0.3 });
				gsap.to(this._buttonSubmit, { opacity: 1, duration: 0.2 });
				this._processing = false;
				activeButtons = ['snapButton'];
				this._currentStep = 0;
				this._captureView.toggleCropDim(false);
				break;
			case 'retake':
				activeButtons = ['buttonRetake'];
				this._currentStep = 0;
				this._captureView.toggleCropDim(false);
				break;
			case 'crop':
				activeButtons = ['back', 'confirm'];
				this._currentStep = 1;
				this._captureView.toggleCropDim(true);
				break;
			case 'keypoints2D':
				// let croppedImage = this._captureView.getCroppedImage();
				// let imageContainer = this._consent.querySelector('.imageContainer');
				// imageContainer.innerHTML = '';
				// imageContainer.appendChild(croppedImage);

				activeButtons = ['back', 'submit'];
				this._currentStep = 2;
				Globals.notifications.triggerNotification('consent');
				break;
			case 'success':
				gsap.to(this._captureView.loader, { opacity: 0, duration: 0.3 });
				// gsap.to(this._spinner, { opacity: 0, duration: 0.3 });
				gsap.to(this._buttonSubmit, { opacity: 1, duration: 0.2 });
				this._currentStep = 3;
				//	this._gestureID.innerText = Steps.formatID(Globals.currentSnapshotID.toString());
				activeButtons = ['restart', 'gallery'];
				this._captureView.setCroppedImage();
				this._captureView.toggleCropDim(false);
				Globals.notifications.reset();
				break;
		}

		gsap.to(this._stepsContainer, {
			opacity: this._currentStep > 2 ? 0 : 1,
			y: this._currentStep > 2 ? 20 : 0,
			duration: 0.3,
			ease: 'power2.out'
		});

		gsap.to(this._titleStep, {
			opacity: this._currentStep > 2 ? 0 : 1,
			y: this._currentStep > 2 ? -20 : 0,
			duration: 0.3,
			ease: 'power2.out'
		});

		let l = this._buttons.length;

		for (let i = 0; i < l; i++) {
			let k = activeButtons.length;
			let active = false;

			for (let j = 0; j < k; j++) {
				if (this._buttons[i].classList.contains(activeButtons[j])) {
					active = true;
					break;
				}
			}

			if (active) {
				this._buttons[i].classList.add('active');
			} else {
				this._buttons[i].classList.remove('active');
			}
		}

		this.updateStep();
	};

	private static formatID(input: string): string {
		let length = 6 - input.length;
		let zeros = '';

		for (let i = 0; i < length; i++) {
			zeros += '0';
		}

		return '#' + zeros + input;
	}

	private updateStep = () => {
		let l = this._steps.length;

		for (let i = 0; i < l; i++) {
			if (i === this._currentStep) {
				this._steps[i].classList.add('active');
			} else {
				this._steps[i].classList.remove('active');
			}
		}

		this._title.innerText = this._titleStepText[this._currentStep];

		if (this._currentStep < 3) {
			this._titleStep.innerText = 'STEP — 0' + (this._currentStep + 1);
		}
	};

	public kill = () => {
		this._buttonSnapshot.removeEventListener('click', this._captureView.snapShot);
		this._buttonConfirm.removeEventListener('click', this.onConfirm);
		this._buttonBack.removeEventListener('click', this.onBackClick);
		this._buttonSubmit.removeEventListener('click', this.onSubmit);
		this._buttonRestart.removeEventListener('click', this._captureView.reset);
		//	this._buttonGallery.removeEventListener('click', this.gotoGallery);
	};
}
