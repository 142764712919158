import { gsap } from 'gsap';
import { Globals } from '../data/Globals';

export class GiveAHandButton {
	private _element: HTMLDivElement;

	constructor(element: HTMLDivElement) {
		this._element = element;

		this._element.addEventListener('click', this.onClick);
	}

	private onClick = event => {
		Globals.VIEW_MANAGER.setPath('give-a-hand');
	};

	private onRollOver = event => {};

	private onRollOut = event => {};

	get element(): HTMLDivElement {
		return this._element;
	}
}
