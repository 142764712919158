import { EventDispatcher, Matrix4, Plane, Ray, Raycaster, Vector2, Vector3 } from 'three';
import { clamp } from 'gsap/gsap-core';

interface DragRestrition {
	axis: string;
	active: boolean;
	min: number;
	max: number;
	disabled: boolean;
}

export class DragControls extends EventDispatcher {
	private _plane: Plane = new Plane();
	private _raycaster: Raycaster = new Raycaster();
	private _mouse: Vector2 = new Vector2();
	private _offset: Vector3 = new Vector3();
	private _intersection: Vector3 = new Vector3();
	private _worldPosition: Vector3 = new Vector3();
	private _inverseMatrix: Matrix4 = new Matrix4();
	private _intersections = [];

	private _objects;
	private _camera;

	private _selected = null;
	private _hovered = null;
	private _domElement: HTMLCanvasElement;

	public enabled: boolean = true;
	public transformGroup = false;

	private _restrictions = {
		x: {
			axis: 'x',
			active: false,
			min: -Infinity,
			max: Infinity,
			disabled: false
		},
		y: {
			axis: 'y',
			active: false,
			min: -Infinity,
			max: Infinity,
			disabled: false
		},
		z: {
			axis: 'z',
			active: false,
			min: -Infinity,
			max: Infinity,
			disabled: false
		}
	};

	constructor(objects, camera, domElement: HTMLCanvasElement, restrictions?: Array<DragRestrition>) {
		super();

		if (restrictions) {
			let l = restrictions.length;

			for (let i = 0; i < l; i++) {
				let restriction = this._restrictions[restrictions[i].axis];
				restriction.active = restrictions[i].active;
				restriction.min = restrictions[i].min;
				restriction.max = restrictions[i].max;
				restriction.disabled = restrictions[i].disabled;
			}
		}

		this._objects = objects;
		this._camera = camera;
		this._domElement = domElement;

		this.activate();
	}

	public activate = () => {
		this._domElement.addEventListener('mousemove', this.onDocumentMouseMove, false);
		this._domElement.addEventListener('mousedown', this.onDocumentMouseDown, false);
		this._domElement.addEventListener('mouseup', this.onDocumentMouseCancel, false);
		this._domElement.addEventListener('mouseleave', this.onDocumentMouseCancel, false);
		this._domElement.addEventListener('touchmove', this.onDocumentTouchMove, false);
		this._domElement.addEventListener('touchstart', this.onDocumentTouchStart, false);
		this._domElement.addEventListener('touchend', this.onDocumentTouchEnd, false);
	};

	public deactivate = () => {
		this._domElement.removeEventListener('mousemove', this.onDocumentMouseMove, false);
		this._domElement.removeEventListener('mousedown', this.onDocumentMouseDown, false);
		this._domElement.removeEventListener('mouseup', this.onDocumentMouseCancel, false);
		this._domElement.removeEventListener('mouseleave', this.onDocumentMouseCancel, false);
		this._domElement.removeEventListener('touchmove', this.onDocumentTouchMove, false);
		this._domElement.removeEventListener('touchstart', this.onDocumentTouchStart, false);
		this._domElement.removeEventListener('touchend', this.onDocumentTouchEnd, false);

		this._domElement.style.cursor = '';
	};

	public dispose() {
		this.deactivate();
	}

	public getObjects() {
		return this._objects;
	}

	public setRestriction = (restriction: DragRestrition) => {
		this._restrictions[restriction.axis].active = restriction.active;
		this._restrictions[restriction.axis].min = restriction.min;
		this._restrictions[restriction.axis].max = restriction.max;
		this._restrictions[restriction.axis].disabled = restriction.disabled;
	};

	get restrictions() {
		return this._restrictions;
	}

	private onDocumentMouseMove = event => {
		if (this._objects.length < 1) {
			return;
		}

		event.preventDefault();

		let rect = this._domElement.getBoundingClientRect();

		this._mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
		this._mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

		this._raycaster.setFromCamera(this._mouse, this._camera);

		if (this._selected && this.enabled) {
			if (this._raycaster.ray.intersectPlane(this._plane, this._intersection)) {
				let copyPosition = this._intersection.sub(this._offset).applyMatrix4(this._inverseMatrix);

				if (this._restrictions['x'].active) {
					copyPosition.x = clamp(this._restrictions['x'].min, this._restrictions['x'].max, copyPosition.x);
				}
				if (this._restrictions['x'].disabled) {
					copyPosition.x = this._selected.position.x;
				}

				if (this._restrictions['y'].active) {
					copyPosition.y = clamp(this._restrictions['y'].min, this._restrictions['y'].max, copyPosition.y);
				}
				if (this._restrictions['y'].disabled) {
					copyPosition.y = this._selected.position.y;
				}

				if (this._restrictions['z'].active) {
					copyPosition.z = clamp(this._restrictions['z'].min, this._restrictions['z'].max, copyPosition.z);
				}
				if (this._restrictions['z'].disabled) {
					copyPosition.z = this._selected.position.z;
				}

				this._selected.position.copy(copyPosition);
			}

			this.dispatchEvent({ type: 'drag', object: this._selected });

			return;
		}

		this._intersections.length = 0;

		this._raycaster.setFromCamera(this._mouse, this._camera);
		this._raycaster.intersectObjects(this._objects, true, this._intersections);

		if (this._intersections.length > 0) {
			let object = this._intersections[0].object;

			this._plane.setFromNormalAndCoplanarPoint(this._camera.getWorldDirection(this._plane.normal), this._worldPosition.setFromMatrixPosition(object.matrixWorld));

			if (this._hovered !== object) {
				this.dispatchEvent({ type: 'hoveron', object: object });

				this._domElement.style.cursor = 'pointer';
				this._hovered = object;
			}
		} else {
			if (this._hovered !== null) {
				this.dispatchEvent({ type: 'hoveroff', object: this._hovered });

				this._domElement.style.cursor = 'auto';
				this._hovered = null;
			}
		}
	};

	private onDocumentMouseDown = event => {
		event.preventDefault();

		this._intersections.length = 0;

		this._raycaster.setFromCamera(this._mouse, this._camera);
		this._raycaster.intersectObjects(this._objects, true, this._intersections);

		if (this._intersections.length > 0) {
			this._selected = this.transformGroup === true ? this._objects[0] : this._intersections[0].object;

			if (this._raycaster.ray.intersectPlane(this._plane, this._intersection)) {
				this._inverseMatrix.getInverse(this._selected.parent.matrixWorld);
				this._offset.copy(this._intersection).sub(this._worldPosition.setFromMatrixPosition(this._selected.matrixWorld));
			}

			this._domElement.style.cursor = 'move';

			this.dispatchEvent({ type: 'dragstart', object: this._selected });
		}
	};

	private onDocumentMouseCancel = event => {
		event.preventDefault();

		if (this._selected) {
			this.dispatchEvent({ type: 'dragend', object: this._selected });

			this._selected = null;
		}

		this._domElement.style.cursor = this._hovered ? 'pointer' : 'auto';
	};

	public updateObjects = objects => {
		this._objects = objects;
	};

	private onDocumentTouchMove = event => {
		event.preventDefault();
		event = event.changedTouches[0];
		console.log(this._domElement);
		let rect = this._domElement.getBoundingClientRect();

		this._mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
		this._mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

		this._raycaster.setFromCamera(this._mouse, this._camera);

		if (this._selected && this.enabled) {
			if (this._raycaster.ray.intersectPlane(this._plane, this._intersection)) {
				this._selected.position.copy(this._intersection.sub(this._offset).applyMatrix4(this._inverseMatrix));
			}

			this.dispatchEvent({ type: 'drag', object: this._selected });

			return;
		}
	};

	private onDocumentTouchStart = event => {
		event.preventDefault();
		event = event.changedTouches[0];

		let rect = this._domElement.getBoundingClientRect();

		this._mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
		this._mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

		this._intersections.length = 0;

		this._raycaster.setFromCamera(this._mouse, this._camera);
		this._raycaster.intersectObjects(this._objects, true, this._intersections);

		if (this._intersections.length > 0) {
			this._selected = this.transformGroup === true ? this._objects[0] : this._intersections[0].object;

			this._plane.setFromNormalAndCoplanarPoint(this._camera.getWorldDirection(this._plane.normal), this._worldPosition.setFromMatrixPosition(this._selected.matrixWorld));

			if (this._raycaster.ray.intersectPlane(this._plane, this._intersection)) {
				this._inverseMatrix.getInverse(this._selected.parent.matrixWorld);
				this._offset.copy(this._intersection).sub(this._worldPosition.setFromMatrixPosition(this._selected.matrixWorld));
			}

			this._domElement.style.cursor = 'move';

			this.dispatchEvent({ type: 'dragstart', object: this._selected });
		}
	};

	private onDocumentTouchEnd = event => {
		event.preventDefault();

		if (this._selected) {
			this.dispatchEvent({ type: 'dragend', object: this._selected });

			this._selected = null;
		}

		this._domElement.style.cursor = 'auto';
	};
}
