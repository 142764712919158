import { WindowManager } from './utils/WindowManager';
import { ModuleFactory } from '../lib/com/hellomonday/core/ModuleFactory';
import { Globals } from './data/Globals';
import { ViewManager } from './managers/ViewManager';
import { TestModule } from './modules/TestModule/TestModule';

import { MainView } from './views/MainView/MainView';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/ScrollSmoother';
import { LinkParser } from './utils/LinkParser';
import { SubpageView } from './views/SubpageView/SubpageView';

import { gsap } from 'gsap';
import { DataManager } from './firebase/DataManager';
import { HandScene } from './3d/HandScene';

import { GiveAHandButton } from './ui/GiveAHandButton';
import { SubMenu } from './ui/SubMenu';
import { CenterCTA } from './ui/CenterCTA';
import { CaptureView } from './views/CaptureView/CaptureView';
import { SpriteAnimation } from './animation/SpriteAnimation';

const plugins = [ScrollToPlugin, ScrollTrigger, ScrollSmoother];

const modules = {
	TestModule
};

class Main {
	private _loader: SpriteAnimation;
	private _loaderLine: HTMLDivElement;
	private _loaderText: HTMLDivElement;
	private _helpingHandsText: HTMLDivElement;
	private _scrollToContinue: HTMLDivElement;
	private _scrollToContinueText: HTMLDivElement;

	private _preloader: HTMLDivElement;

	private _subtitles: HTMLDivElement;
	private _heroVideo: HTMLVideoElement;

	private _volumeOn: HTMLDivElement;
	private _volumeOff: HTMLDivElement;
	private _volumeButtons: HTMLDivElement;

	constructor() {
		gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrollSmoother);

		ScrollTrigger.normalizeScroll(true);

		if (window.location.href.indexOf('autoplay') > -1) {
			Globals.DEBUG_AUTOPLAY = true;
		}
		if (window.location.href.indexOf('hideUI') > -1) {
			document.body.querySelector('#outerElements').style.display = 'none';
			document.body.querySelector('#Preloader').style.display = 'none';
			document.body.querySelector('#outerElementsInfo').style.display = 'none';
			document.body.querySelector('#IntroElements').style.display = 'none';
			document.body.querySelector('#centerCTA').style.display = 'none';
		}

		if (Globals.IS_TOUCH_DEVICE && window.innerWidth < 1000) {
			Globals.IS_MOBILE = true;

			this._heroVideo = document.querySelector('#HeroVideo');
			this._heroVideo.style.display = 'none';
			var clickToStart = document.body.querySelector('#ClickToStart');
			clickToStart.style.display = 'none';
		}

		/*if (window.location.hash !== '') {
			window.location.hash = '';
		}*/

		// Clear the URL (no deeplinking)
		history.pushState('', document.title, window.location.pathname + window.location.search);

		// create the scrollSmoother before your scrollTriggers
		/*ScrollSmoother.create({
			smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
			effects: true,           // looks for data-speed and data-lag attributes on elements
			smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
		});*/

		gsap.to(this._loaderLine, { duration: 2, scaleX: 0.2, ease: 'none' });

		Globals.MAIN_VIEW_CONTAINER = document.body.querySelector('#mainViewContainer');

		this._preloader = document.body.querySelector('#Preloader');
		this._loaderText = document.body.querySelector('.loaderText');
		this._loaderLine = document.body.querySelector('.loaderLine');
		this._helpingHandsText = document.body.querySelector('h1');
		this._scrollToContinue = document.body.querySelector('.scrollToContinue');
		this._scrollToContinueText = this._scrollToContinue.querySelector('.scrollToContinueText');

		this._volumeOn = document.body.querySelector('.volumeOn');
		this._volumeOff = document.body.querySelector('.volumeOff');
		this._volumeButtons = document.body.querySelector('.volumeButtons');

		this._volumeButtons.addEventListener('click', this.onVolumeClick);

		Globals.DATA_MANAGER = new DataManager(this.connectedToFireBase);
	}

	private onVolumeClick = (e: MouseEvent) => {
		if (Globals.SOUND_ON === true) {
			this._volumeOn.style.opacity = '0';
			this._volumeOff.style.opacity = '1';
			Globals.SOUND_ON = false;
			this._heroVideo.muted = true;
		} else {
			this._heroVideo.muted = false;
			this._volumeOn.style.opacity = '1';
			this._volumeOff.style.opacity = '0';
			Globals.SOUND_ON = true;
		}
	};

	private onBodyClick = (e: MouseEvent) => {
		document.body.removeEventListener('click', this.onBodyClick);

		var clickToStart = document.body.querySelector('#ClickToStart');
		var mainHeader = document.body.querySelector('.mainHeader');
		clickToStart.style.pointerEvents = 'none';
		gsap.killTweensOf(clickToStart);
		gsap.to(clickToStart, { duration: 0.35, opacity: 0, ease: 'power1.in' });
		gsap.to(mainHeader, { duration: 0.65, scale: 0.8, opacity: 0, ease: 'power1.in' });

		Globals.HAND_SCENE.setupScroll();
		this.animatedIn();

		this._subtitles = document.querySelector('#subtitles');

		this._heroVideo = document.querySelector('#HeroVideo');
		this._heroVideo.addEventListener('timeupdate', this.closedCaptionsUpdate);
		this._heroVideo.play();

		gsap.to(this._scrollToContinueText, { duration: 2, yoyo: true, repeat: -1, opacity: 0.4, ease: 'power1.inOut' });
		gsap.to(this._scrollToContinue, { duration: 2, opacity: 1, x: 0, ease: 'power1.inOut', delay: 0 });
		gsap.to(this._volumeButtons, { duration: 2, opacity: 1, x: 0, ease: 'power1.inOut', delay: 0 });
	};

	private closedCaptionsUpdate = (e: any) => {
		var getTime = this._heroVideo.currentTime;

		//	console.log(getTime)

		if (getTime > 1.2 && getTime < 5.5) {
			this._subtitles.innerHTML = 'Technology has revolutionized how we communicate across languages.';
			Globals.HAS_SCROLLED = false;
		} else if (getTime > 5.5 && getTime < 11) {
			this._subtitles.innerHTML = 'Tools for live audio transcription and translation are breaking down barriers between people around the world.';
		} else if (getTime > 13 && getTime < 17) {
			this._subtitles.innerHTML = 'However, deaf and hard-of-hearing people are missing out on these opportunities.';
		} else if (getTime > 17 && getTime < 24) {
			this._subtitles.innerHTML = 'Sign language involves a combination of fast-paced hand gestures, facial expressions and even full body movement.';
		} else if (getTime > 24 && getTime < 32) {
			this._subtitles.innerHTML =
				'And while machine learning models deal well with facial expressions and body movements, it still has a long way to go when it comes to hand and finger detection.';
		} else if (getTime > 34 && getTime < 40) {
			this._subtitles.innerHTML = 'To register the full spectrum of sign language, we need better models of hand detection.';
		} else if (getTime > 40 && getTime < 44) {
			this._subtitles.innerHTML = 'And to get better models, we need more and better data.';
		} else if (getTime > 44 && getTime < 47) {
			this._subtitles.innerHTML = 'In this case: Data equals Hands.';
		} else if (getTime > 47 && getTime < 49) {
			this._subtitles.innerHTML = 'Simple.';
		} else if (getTime > 49 && getTime < 55) {
			this._subtitles.innerHTML = 'So, in collaboration with the American Society for Deaf Children, we created GiveAHand.ai.';
		} else if (getTime > 55 && getTime < 60) {
			this._subtitles.innerHTML = 'A website for collecting as many hand images as possible through crowdsourcing.';
		} else if (getTime > 60 && getTime < 66) {
			this._subtitles.innerHTML = 'Now, anyone can put their hands to good use, by contributing and uploading images. ' + 'And build an image library that will help unlock sign language.';
		} else if (getTime > 69 && getTime < 75) {
			this._subtitles.innerHTML = 'Researchers can then download and use these fully tagged images to improve their machine learning models.';
		} else if (getTime > 76 && getTime < 80) {
			this._subtitles.innerHTML = 'So that we can go from recognizing simple hand gestures to detecting and translating the full spectrum of sign language.';
		} else if (getTime > 83 && getTime < 86) {
			this._subtitles.innerHTML = 'And break down communication barriers.';
		} else if (getTime > 86 && getTime < 90) {
			this._subtitles.innerHTML = 'Giving everyone equal access to language tools.';
		} else if (getTime > 90 && getTime < 97) {
			this._subtitles.innerHTML = 'Deafness is not a disability, but language deprivation is.';
		} else if (getTime > 97 && getTime < 100) {
			this._subtitles.innerHTML = '';
		} else if (getTime > 100 && Globals.HAS_SCROLLED === false) {
			Globals.HAND_SCENE.scrollToFirstTitle();
			Globals.HAS_SCROLLED = true;
		}

		//console.log(getTime)
	};

	private connectedToFireBase = () => {
		Globals.DATA_MANAGER.getNumberOfGestures().then(this.returnGestureCount);

		//this.gesturesLoaded();
	};

	/*	private connectToFirebase = () => {
		Globals.DATA_MANAGER = new DataManager(this.fetchAll);
	};*/

	private returnGestureCount = count => {
		//console.log(count);
		//	Globals.TOTAL_GESTURE_COUNT = count;
		this.fetchAll();
	};

	private fetchAll = () => {
		let gestures = Globals.DATA_MANAGER.getXNumberOfRandomHands(this.proccessGestures);
	};

	private proccessGestures = result => {
		this._loaderText.innerHTML = 'Loading data...';
		gsap.to(this._loaderLine, { duration: 4, scaleX: 0.6, ease: 'none' });
		// Load spritesheet data for loader
		fetch('assets/data/loader.json').then(result => {
			result.json().then(data => {
				//console.log(data);
				Globals.LOADER_JSON = data;
				this.gesturesLoaded();
			});
		});
	};

	private gesturesLoaded = () => {
		this._loaderText.innerHTML = 'Loading 3D...';
		gsap.killTweensOf(this._loaderLine);
		gsap.to(this._loaderLine, { duration: 1, scaleX: 0.8, ease: 'none' });

		//	Globals.DATA_MANAGER.getRandomHand();

		// Add Loader
		/*this._loader = new SpriteAnimation(Globals.LOADER_JSON, document.body.querySelector('.loaderAnim'), 1);
		this._loader.loop = true;
		this._loader.play();*/
		WindowManager.getInstance();

		// Globals.GUI = new dat.GUI();
		//
		// let url = new URL(window.location.href);
		// if (url.origin.indexOf('localhost') === -1) {
		// 	Globals.GUI.domElement.parentNode.style.display = 'none';
		// }
		//
		// Globals.GUI.domElement.parentNode.style.zIndex = 3;

		Globals.VIEW_MANAGER = new ViewManager(document.body.querySelector('#ViewContainer'));
		LinkParser.getInstance(Globals.VIEW_MANAGER);

		ModuleFactory.registerModules(modules);
		//this.init();

		Globals.HAND_SCENE = new HandScene(this.onHandLoaded);
		Globals.HAND_SCENE.loadHand(this.onHandLoaded, this.onLoadProgress);

		Globals.HAND_DATA_ELEMENT = document.getElementById('HandData') as HTMLDivElement;
		var getReturnToGalleryButton = Globals.HAND_DATA_ELEMENT.querySelector('.closeButton');
		getReturnToGalleryButton.addEventListener('click', Globals.HAND_SCENE.clickCloseHandView);

		Globals.GIVE_A_HAND_BUTTON = new GiveAHandButton(document.body.querySelector('#giveAHandCTA'));
		Globals.SUB_MENU = new SubMenu(document.body.querySelector('#subMenu'));
		Globals.LOGO_TOP_LEFT = document.body.querySelector('#logo');

		Globals.CENTER_CTA = new CenterCTA(document.body.querySelector('#centerCTA'));
	};

	private onHandLoaded = () => {
		//	console.log('hand loaded');
		this._loaderText.innerHTML = 'Preparing...';
		gsap.killTweensOf(this._loaderLine);
		gsap.to(this._loaderLine, { duration: 1, scaleX: 0.97, ease: 'none', onComplete: this.init });
	};

	private init = () => {
		//	gsap.to(this._preloader, { duration: 2, scale: 0.8, ease: 'power1.inOut' });
		//	gsap.to(this._helpingHandsText, { scale: 0.8, ease: 'power1.inOut', delay: 0 });
		gsap.to(this._loaderLine, { duration: 0.5, opacity: 0, y: -0, ease: 'power1.in' });
		gsap.to(this._loaderText, { duration: 0.5, opacity: 0, y: -10, ease: 'power1.in' });
		//gsap.set(this._scrollToContinue, { scale: 2 });

		if (Globals.IS_MOBILE) {
		} else {
			gsap.to('#ClickToStart', { duration: 2, opacity: 1, ease: 'power1.inOut', delay: 0 });
		}

		if (Globals.IS_MOBILE) {
			Globals.HAND_SCENE.setupScroll();
			this.animatedIn();

			gsap.to(this._scrollToContinueText, { duration: 2, yoyo: true, repeat: -1, opacity: 0.4, ease: 'power1.inOut' });
			gsap.to(this._scrollToContinue, { duration: 2, opacity: 1, ease: 'power1.inOut', delay: 0 });
		} else {
			document.body.addEventListener('click', this.onBodyClick);
			var clickToStart = document.body.querySelector('#ClickToStart');
			clickToStart.style.cursor = 'pointer';
		}

		//console.log(Globals.HAND_SCENE);
		//var getHand = Globals.HAND_SCENE.getHand();
		//gsap.to(getHand.position, { delay: 1, duration: 1, z: 50, ease: 'power3.out' });
	};

	private animatedIn = () => {
		Globals.VIEW_MANAGER.registerView({ name: 'MainView', view: MainView });
		Globals.VIEW_MANAGER.registerView({ name: 'CaptureView', view: CaptureView });
		Globals.VIEW_MANAGER.registerView({ name: 'SubpageView', view: SubpageView });
		Globals.VIEW_MANAGER.init();

		window.dispatchEvent(new Event('resize'));

		WindowManager.signalResize.add(this.resize);
		this.resize();

		var getAboutButton = document.body.querySelector('.menuAbout');
		var getTermsButton = document.body.querySelector('.menuTerms');
		var getDownloadDataButton = document.body.querySelector('.menuDownloadData');
		getAboutButton.addEventListener('click', this.clickAbout);
		getTermsButton.addEventListener('click', this.clickTerms);
		getDownloadDataButton.addEventListener('click', this.clickDownloadData);
	};

	private clickAbout = () => {
		Globals.VIEW_MANAGER.setPath('about-the-project');
	};

	private clickDownloadData = () => {
		Globals.VIEW_MANAGER.setPath('download-data');
	};

	public clickTerms = () => {
		window.open('/terms.html', '_blank');
	};

	private onLoadProgress = progress => {};

	public resize = () => {
		Globals.VIEW_MANAGER.resize();
	};
}

window.onload = () => {
	const main = new Main();
	(window as any).Main = main;
};
