import { AbstractView } from '../core/AbstractView';
import { CameraFeed } from '../../camera/CameraFeed';
import { gsap } from 'gsap';
import * as THREE from 'three';
import { Globals } from '../../data/Globals';
import { Gesture } from '../../data/Gesture';
import { MeshLine, MeshLineMaterial } from 'threejs-meshline';
import { DragControls } from '../../three/controls/DragControls';
import { Notifications } from '../../ui/Notifications';
import { ImageUtils } from '../../../lib/com/hellomonday/utils/ImageUtils';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { MediaPipeClient } from '../../utils/MediaPipeClient';
import { clamp } from '../../../lib/com/hellomonday/utils/MathUtils';
import { WorldPane } from '../../utils/WorldPane';
import { RayBounds } from '../../utils/RayBounds';
import { WindowManager } from '../../utils/WindowManager';
import { Resizer } from '../../../lib/com/hellomonday/utils/Resizer';
import { Steps } from '../MainView/ui/Steps';
import { SpriteAnimation } from '../../animation/SpriteAnimation';
import { CaptureJoints } from './CaptureJoints';

const PLANE_SIZE: number = 0.001; //0.15;

export class CaptureView extends AbstractView {
	private _cameraFeed: CameraFeed;
	private _imageStep: number = 0;

	private _camera: THREE.PerspectiveCamera;
	private scene: THREE.Scene;
	private mesh: THREE.Mesh;
	private renderer: THREE.WebGLRenderer;

	private _active: boolean = false;

	private _mediaPipeClient: MediaPipeClient = new MediaPipeClient();

	private _mainContainer: THREE.Group = new THREE.Group();

	private _handJoints: CaptureJoints;

	private _referenceSnapshot;

	private _worldProperties = {
		cameraZ: 0.18, //0.3,
		cameraX: 0,
		cameraY: 0
	};

	private _dragControls: DragControls;

	private _lineMesh: THREE.Mesh;
	private _objects = [];

	private _objectRefs = {};

	private _lineMaterial;

	private _greenTexture;
	private _blueTexture;

	private _steps: Steps;

	private _landmarks;

	private _cropMask: THREE.Mesh;

	private _bounds = {
		x: 0,
		y: 0,
		w: 0,
		h: 0
	};

	private _textureWidth: number;
	private _textureHeight: number;
	private _textureRatio: number;

	private _focusedZ: number;

	private _controls: OrbitControls;

	private _boundingBoxInitialized: boolean = false;
	private _saveInProgress: boolean = false;
	private _3dViewEnabled: boolean = false;
	private _estimationFailed: boolean = false;
	private _boundingBoxActive: boolean = false;

	private _outerContainer: THREE.Group = new THREE.Group();

	private _centerX: number = 0;
	private _centerY: number = 0;

	private _raybounds: RayBounds;
	private _screenPixelBounds = { width: 0, height: 0 };

	private _currentBoundingBox;

	private _closeButton: HTMLElement;

	private _titleStep: HTMLElement;
	private _title: HTMLElement;
	private _stepElement: HTMLElement;

	private _loader: SpriteAnimation;
	private _loaderContainer: HTMLDivElement;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		Globals.HAND_SCENE.switchToGiveAHand();
		// WorldPane.getInstance();

		this._titleStep = element.querySelector('.titleStep');
		this._title = element.querySelector('.title');
		this._stepElement = element.querySelector('.steps');

		this._loaderContainer = element.querySelector('.loader');

		this._loader = new SpriteAnimation(Globals.LOADER_JSON, element.querySelector('.loader .imageContainer'), 1);

		this._loader.loop = true;
		this._loader.play();

		gsap.set(this._titleStep, { y: window.innerHeight * -0.5 - 200 });
		gsap.set(this._title, { y: window.innerHeight * -0.5 });
		gsap.set(this._stepElement, { y: window.innerHeight * 0.5 });

		gsap.to(this._titleStep, { y: 0, duration: 1, ease: 'power1.out' });
		gsap.to(this._title, { y: 0, duration: 1, ease: 'power1.out' });
		gsap.to(this._stepElement, { y: 0, duration: 1, ease: 'power1.out' });

		this._closeButton = element.querySelector('.closeButton');
		this._closeButton.addEventListener('click', this.onCloseClick);

		this._cameraFeed = new CameraFeed();
		this._cameraFeed.completeSignal.add(this.cameraReady);

		this._steps = new Steps(element as HTMLDivElement, this);

		this._blueTexture = new THREE.TextureLoader().load('/assets/images/dragHandle_blue.jpg');
		this._greenTexture = new THREE.TextureLoader().load('/assets/images/dragHandle2.jpg');

		Globals.notifications = new Notifications(element.querySelector('.notifications'));

		this._referenceSnapshot = element.querySelector('.referenceSnapshot');

		this._mediaPipeClient.onResultSignal.add(this.onMediaPipeResults);
	}

	private onCloseClick = () => {
		Globals.VIEW_MANAGER.setPath('');
	};

	private init = () => {
		this._camera = new THREE.PerspectiveCamera(window.innerHeight / window.screen.height, window.innerWidth / window.innerHeight, 0.01, 500);
		this._camera.position.z = 0.18; //0.3;
		//this._camera.position.z = 0.01;

		this.scene = new THREE.Scene();

		let texture = new THREE.VideoTexture(this._cameraFeed.video);
		texture.minFilter = THREE.LinearFilter;
		texture.magFilter = THREE.LinearFilter;
		texture.format = THREE.RGBFormat;

		this._raybounds = new RayBounds(this._camera, this.scene);
		this._raybounds.update(WindowManager.height - 170, 170);

		this.mesh = new THREE.Mesh();
		this.updateMesh(texture, this._cameraFeed.video.videoWidth, this._cameraFeed.video.videoHeight);

		this.scene.add(this._outerContainer);

		this._outerContainer.add(this._mainContainer);

		this._mainContainer.add(this.mesh);

		// this.initGUI();

		// this.mesh.geometry.computeBoundingSphere();
		// this.mesh.geometry.computeBoundingBox();

		// const geometry = new THREE.PlaneGeometry(1, 1, 1, 1);
		// const material = new THREE.MeshBasicMaterial({color: 0xFF0000});
		// this._testPlane = new THREE.Mesh(geometry, material);
		// this.scene.add(this._testPlane);

		//this.setPlaneFromCSSPixelValues(this.mesh, this._camera, 0, 0, 500, 800);

		this.renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: 'high-performance', alpha: true });
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.element.appendChild(this.renderer.domElement);
		this.renderer.setClearColor(0xffffff, 0);

		let video = this._cameraFeed.video;

		this._handJoints = new CaptureJoints(video, this._textureRatio, this._camera, this.renderer, this.element, this);
		//	this._mainContainer.add(this._handJoints);

		// Flip container to mirror webcam
		this._mainContainer.rotation.y = Math.PI;

		this._lineMaterial = new MeshLineMaterial({ color: 0x17ab16, lineWidth: 0.0009 * 0.01, transparent: true, opacity: 0.75, dashArray: 0.02, dashRatio: 0.3 }); // ,
		gsap.to(this._lineMaterial, { duration: 20, dashOffset: 1, repeat: -1, ease: 'none' });

		this._active = true;

		this.initMask();
		this.initDragControls();

		this.resize();
	};

	private initGUI = () => {
		let pane = WorldPane.getInstance();
		let folder = pane.addFolder({ title: 'Main Container' });

		folder.addInput(this._mainContainer.position, 'x', { step: 0.000001, min: -0.01, max: 0.01 });
		folder.addInput(this._mainContainer.position, 'y', { step: 0.000001, min: -0.01, max: 0.01 });
		folder.addInput(this._mainContainer.position, 'z', { step: 0.000001, min: -0.01, max: 0.01 });

		folder = pane.addFolder({ title: 'Outer Container' });
		folder.addInput(this._outerContainer.position, 'x', { step: 0.0001, min: -0.01, max: 0.01 });
		folder.addInput(this._outerContainer.position, 'y', { step: 0.0001, min: -0.01, max: 0.01 });
		folder.addInput(this._outerContainer.position, 'z', { step: 0.0001, min: -0.1, max: 0.1 });
	};

	private initDragControls = () => {
		let bounds = this.getTextureBounds();
		let textureWidth = bounds.width; //PLANE_SIZE * this._textureRatio;
		let textureHeight = bounds.height; //PLANE_SIZE;

		this._dragControls = new DragControls(this._objects, this._camera, this.renderer.domElement, [
			{ axis: 'x', active: true, min: -(textureWidth * 0.5), max: textureWidth * 0.5, disabled: false },
			{ axis: 'y', active: true, min: -(textureHeight * 0.5), max: textureHeight * 0.5, disabled: false },
			{ axis: 'z', active: true, min: -0.001, max: -0.001, disabled: false }
		]);
		this._dragControls.addEventListener('dragstart', this.onDragStart);
		this._dragControls.addEventListener('drag', this.onDrag);
		this._dragControls.addEventListener('dragend', this.onDragEnd);
	};

	private initMask = () => {
		let maskShape = new THREE.Shape();

		let textureWidth = PLANE_SIZE * this._textureRatio;
		let textureHeight = PLANE_SIZE;

		let halfW = textureWidth * 0.5;
		let halfH = textureHeight * 0.5;

		let padding = 3;

		maskShape.moveTo(-halfW - padding, -halfH - padding);
		maskShape.lineTo(halfW + padding, -halfH - padding);
		maskShape.lineTo(halfW + padding, halfH + padding);
		maskShape.lineTo(-halfW - padding, halfH + padding);

		let hole = new THREE.Shape();
		hole.moveTo(-halfW, -halfH);
		hole.lineTo(halfW, -halfH);
		hole.lineTo(halfW, halfH);
		hole.lineTo(-halfW, halfH);

		maskShape.holes = [hole];

		let geometry = new THREE.ShapeGeometry(maskShape);
		this._cropMask = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide, transparent: true, opacity: 0 }));
		this._cropMask.position.z = 0; //0.001;
		this._mainContainer.add(this._cropMask);

		this._controls = new OrbitControls(this._camera, this.renderer.domElement);
		this._controls.enabled = false;
	};

	public reset = () => {
		gsap.to(this.mesh.material, { opacity: 0, duration: 0.3 });

		// gsap.to(this._mainContainer.position, {
		// 	duration: 0.3,
		// 	z: -0.2,
		// 	onComplete: () => {
		this._estimationFailed = false;
		Globals.notifications.reset();

		let texture = new THREE.VideoTexture(this._cameraFeed.video);
		texture.minFilter = THREE.LinearFilter;
		texture.magFilter = THREE.LinearFilter;
		texture.format = THREE.RGBFormat;

		let video = this._cameraFeed.video;

		this.updateMesh(texture, video.videoWidth, video.videoHeight);
		this.resetPosition();

		// Reset back to first stage
		this._steps.setStage('snap');
		// 	}
		// });
	};

	private resetPosition = () => {
		// Clear bounding box
		let l = this._objects.length;

		for (let i = 0; i < l; i++) {
			this._mainContainer.remove(this._objects[i]);
		}

		this._objects = [];
		this._dragControls.updateObjects(this._objects);

		if (this._lineMesh) {
			this._mainContainer.remove(this._lineMesh);
			this._lineMesh = null;
		}

		this.updateCropMask();

		gsap.to(this.mesh.material, { opacity: 1, duration: 0.3 });

		// Reset hand joints
		this._handJoints.reset();

		// Reset camera
		// gsap.to(this._mainContainer.position, {duration: 0.5, x: 0, y: 0, z: 0});

		gsap.to(this._outerContainer.position, { duration: 0.5, z: 0 });

		gsap.to(this, { _centerX: 0, _centerY: 0, onUpdate: this.updateCenter });

		this.resetCamera(false);
		this.toggleBoundingBox(false);
	};

	private updateCenter = () => {
		this._mainContainer.position.x = this._centerX * 0.001;
		this._mainContainer.position.y = -(this._centerY * 0.001);
	};

	public resetHandjoints = () => {
		this.toggleBoundingBox(true);
		this._handJoints.reset();
	};

	public snapShot = () => {
		if (this._estimationFailed) {
			this.reset();
		}

		let image = this._cameraFeed.captureImage();
		image.addEventListener('load', this.imageCaptureLoaded);
	};

	private imageCaptureLoaded = e => {
		Globals.currentSnapshot = e.currentTarget;

		let texture = new THREE.Texture();
		texture.image = e.currentTarget;
		texture.needsUpdate = true;

		// this._material = new THREE.MeshBasicMaterial({map: texture, side: THREE.DoubleSide, transparent: true});
		// this.mesh.material = this._material;

		this.updateMesh(texture, texture.image.width, texture.image.height);

		this._imageStep++;

		this._mediaPipeClient.estimate(e.currentTarget).then(() => {
			//	console.log('estimation complete');

			gsap.killTweensOf(this.resetOnError);
			gsap.delayedCall(3, this.resetOnError);
		});
	};

	private resetOnError = () => {
		if (this._estimationFailed) {
			this.reset();
		}
	};

	private onMediaPipeResults = (result, boundingBox, size) => {
		if (!result) {
			this.estimateFailed(null);
		} else {
			let outOfBounds = this.checkIfOutOfBounds(result);

			if (outOfBounds) {
				//	console.log('landmarks out of bounds');
			}

			this._landmarks = result;

			this._currentBoundingBox = boundingBox;

			this.initBoundingBox(boundingBox);
			this.toggleBoundingBox(true);
			this.focusBoundingBox();
		}
	};

	private estimateFailed = e => {
		Globals.notifications.triggerNotification('error');

		//	console.log(e);
		//	console.log('estimateFailed');
		this._estimationFailed = true;
		this._steps.setStage('snap');
	};

	public cropToBoundingBox = () => {
		this.toggleBoundingBox(false);

		this._handJoints.updatePoints(this._landmarks);
		this._steps.setStage('keypoints2D');
	};

	private toggleBoundingBox = (state: boolean) => {
		this._boundingBoxActive = state;

		if (state === true) {
			Globals.notifications.reset();
		}

		let l = this._objects.length;

		for (let i = 0; i < l; i++) {
			gsap.to(this._objects[i].material, { duration: 0.1, opacity: state ? 1 : 0 });
		}

		gsap.to(this._lineMaterial, { duration: 0.3, opacity: state ? 1 : 0 });

		if (state) {
			this._dragControls.activate();
		} else {
			this._dragControls.deactivate();
		}
	};

	private initBoundingBox = boundingBox => {
		let video = this._cameraFeed.video;
		// let ratio = video.videoWidth / video.videoHeight;

		let bounds = this.getTextureBounds();
		let textureWidth = bounds.width; //PLANE_SIZE * this._textureRatio; //ratio;
		let textureHeight = bounds.height; //PLANE_SIZE;

		let scaleX = this._screenPixelBounds.width / video.videoWidth;
		let scaleY = this._screenPixelBounds.height / video.videoHeight;

		// boundingBox.topLeft[0] *= scaleX;
		// boundingBox.topLeft[1] *= scaleY;

		// boundingBox.bottomRight[0] *= scaleX;
		// boundingBox.topLeft[1] *= scaleY;

		let points = [
			// Top Left
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.topLeft[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.topLeft[1] / video.videoHeight),
				-0.01
			),
			// Top Right
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.bottomRight[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.topLeft[1] / video.videoHeight),
				-0.01
			),
			// Bottom Right
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.bottomRight[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.bottomRight[1] / video.videoHeight),
				-0.01
			),
			// Bottom Left
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.topLeft[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.bottomRight[1] / video.videoHeight),
				-0.01
			)
		];

		//
		// console.log(video.videoWidth - boundingBox.topLeft[0]);
		// //
		// console.log(this._screenPixelBounds);
		// //
		// console.log((boundingBox.topLeft[0] * scaleX) / this._screenPixelBounds.width);

		// let points = [
		// 		// Top Left
		// 		new THREE.Vector3(
		// 			-(textureWidth * 0.5) + textureWidth * (boundingBox.topLeft[0] / this._screenPixelBounds.width),
		// 			textureHeight * 0.5 - textureHeight * (boundingBox.topLeft[1] / this._screenPixelBounds.height),
		// 			-0.01
		// 		),
		// 		// Top Right
		// 		new THREE.Vector3(
		// 			-(textureWidth * 0.5) + textureWidth * (boundingBox.bottomRight[0] / this._screenPixelBounds.width),
		// 			textureHeight * 0.5 - textureHeight * (boundingBox.topLeft[1] / this._screenPixelBounds.height),
		// 			-0.01
		// 		),
		// 		// Bottom Right
		// 		new THREE.Vector3(
		// 			-(textureWidth * 0.5) + textureWidth * (boundingBox.bottomRight[0] / this._screenPixelBounds.width),
		// 			textureHeight * 0.5 - textureHeight * (boundingBox.bottomRight[1] / this._screenPixelBounds.height),
		// 			-0.01
		// 		),
		// 		// Bottom Left
		// 		new THREE.Vector3(
		// 			-(textureWidth * 0.5) + textureWidth * (boundingBox.topLeft[0] / this._screenPixelBounds.width),
		// 			textureHeight * 0.5 - textureHeight * (boundingBox.bottomRight[1] / this._screenPixelBounds.height),
		// 			-0.01
		// 		)
		// 	];

		let l = points.length;

		let outOfBounds = this.checkIfOutOfBounds(points);
		//	console.log('bounding box outOfBounds: ' + outOfBounds);

		this.normalizePoints(points);

		// if (!outOfBounds) {
		//let geometry = new THREE.SphereBufferGeometry(0.005, 32, 32);
		let geometry = new THREE.BoxBufferGeometry(0.005 * 0.01, 0.005 * 0.01, 0.00001);

		for (let i = 0; i < l; i++) {
			let material = new THREE.MeshBasicMaterial({ color: 0xffffff, map: this._greenTexture, transparent: true }); // 0x17ab16
			let dragHandle = new THREE.Mesh(geometry, material);

			//@ts-ignore
			dragHandle._id = i;
			dragHandle.position.x = points[i].x;
			dragHandle.position.y = points[i].y;
			dragHandle.position.z = -0.001; //points[i].z;
			this._objectRefs['id-' + i] = dragHandle;
			this._mainContainer.add(dragHandle);
			this._objects.push(dragHandle);
		}

		this._boundingBoxInitialized = true;

		this.drawLines(points);

		this.focusBoundingBox();

		this._steps.setStage('crop');

		this.updateBounds();
	};

	private updateBounds = () => {
		let l = this._objects.length;
		//let video = this._cameraFeed.video;
		//let ratio = video.videoWidth / video.videoHeight;

		let bounds = this.getTextureBounds();
		let textureWidth = bounds.width; //PLANE_SIZE * this._textureRatio;
		let textureHeight = bounds.height; //PLANE_SIZE;

		let xMin = 9999;
		let yMin = 9999;

		let xMax = 0;
		let yMax = 0;

		for (let i = 0; i < l; i++) {
			let pos = this._objects[i].position;

			let x = -(textureWidth * 0.5) + pos.x;
			let y = textureHeight * 0.5 + pos.y;

			let xPos = ((x * -1) / textureWidth) * this._textureWidth;
			let yPos = this._textureHeight - (y / textureHeight) * this._textureHeight;

			xMin = Math.min(xMin, xPos);
			yMin = Math.min(yMin, yPos);

			xMax = Math.max(xMax, xPos);
			yMax = Math.max(yMax, yPos);
		}

		this._bounds.x = xMin;
		this._bounds.y = yMin;
		this._bounds.w = xMax - xMin;
		this._bounds.h = yMax - yMin;

		this._handJoints.updateOffset(this._bounds.x, this._bounds.y);
	};

	private normalizePoints = (points: Array<THREE.Vector3>) => {
		let l = points.length;

		for (let i = 0; i < l; i++) {
			if (this._dragControls.restrictions['x'].active) {
				points[i].x = clamp(this._dragControls.restrictions['x'].min, this._dragControls.restrictions['x'].max, points[i].x);
			}
			if (this._dragControls.restrictions['y'].active) {
				points[i].y = clamp(this._dragControls.restrictions['y'].min, this._dragControls.restrictions['y'].max, points[i].y);
			}
		}
	};

	private updateDragRestrictions = () => {
		let bounds = this.getTextureBounds();
		let textureWidth = bounds.width;
		let textureHeight = bounds.height;

		this._dragControls.restrictions.x.min = -(textureWidth * 0.5);
		this._dragControls.restrictions.x.max = textureWidth * 0.5;

		this._dragControls.restrictions.y.min = -(textureHeight * 0.5);
		this._dragControls.restrictions.y.max = textureHeight * 0.5;
	};

	private checkIfOutOfBounds = points => {
		let l = points.length;

		for (let i = 0; i < l; i++) {
			if (this._dragControls.restrictions['x'].active && (points[i].x < this._dragControls.restrictions['x'].min || points[i].x > this._dragControls.restrictions['x'].max)) {
				return true;
			}
			if (this._dragControls.restrictions['y'].active && (points[i].y < this._dragControls.restrictions['y'].min || points[i].y > this._dragControls.restrictions['y'].max)) {
				return true;
			}
		}

		return false;
	};

	private resetCamera = (focus: boolean = true) => {
		gsap.to(this._worldProperties, { duration: 0.5, cameraX: 0, cameraY: 0, cameraZ: 0.18, onUpdate: this.updateCamera, ease: 'quad.inOut' });

		if (focus) {
			this.focusBoundingBox();
		}
	};

	private updateCamera = () => {
		this._camera.position.x = this._worldProperties.cameraX;
		this._camera.position.y = this._worldProperties.cameraY;
		this._camera.position.z = this._worldProperties.cameraZ;

		this._camera.lookAt(0, 0, 0);
	};

	private drawLines = (points: Array<THREE.Vector3>) => {
		points.push(points[0].clone());

		if (this._lineMesh) {
			this._mainContainer.remove(this._lineMesh);
			this._lineMesh = null;
		}
		let geometry = new THREE.Geometry();

		let l = points.length;
		for (let i = 0; i < l; i++) {
			let point = points[i].clone();
			point.z = -0.0001;
			geometry.vertices.push(point);
		}

		let line = new MeshLine();
		line.setGeometry(geometry);

		this._lineMesh = new THREE.Mesh(line.geometry, this._lineMaterial);
		this._mainContainer.add(this._lineMesh);
	};

	public saveGesture = () => {
		this._saveInProgress = true;

		//	console.log('saveGesture');

		let gesture = new Gesture();
		gesture.image = this.getCroppedImage().toDataURL();
		gesture.timeStamp = Math.round(+new Date() / 1000) + '';

		// We add a random number to make it easier to pull out random numbers
		gesture.random = Math.random() * 10000000;

		let jointPositions = this._handJoints.getNormalizedPositions();
		let l = jointPositions.length;

		for (let i = 0; i < l; i++) {
			gesture['key_point_' + i].x = jointPositions[i].x;
			gesture['key_point_' + i].y = jointPositions[i].y;
			gesture['key_point_' + i].z = this._landmarks[i][2]; //jointPositions[i].z;
		}

		console.log(gesture);

		Globals.DATA_MANAGER.storeGesture({ ...gesture }).then(this.gestureStoreCallback);
	};

	private gestureStoreCallback = () => {
		this._saveInProgress = false;
		//	console.log('stored');

		this._steps.setStage('success');
	};

	private cameraReady = () => {
		this.init();
		this.animate();
	};

	private focusBoundingBox = () => {
		if (this._boundingBoxInitialized) {
			if (this._boundingBoxActive) {
				let centerX = (this._objects[0].position.x + this._objects[1].position.x) * 0.5;
				let centerY = (this._objects[0].position.y + this._objects[3].position.y) * 0.5;
				gsap.to(this, { _centerX: centerX * 1000, _centerY: centerY * 1000, onUpdate: this.updateCenter });

				let bounds = this.getTextureBounds();
				let textureWidth = bounds.width; //PLANE_SIZE * this._textureRatio; //ratio;
				let textureHeight = bounds.height; //PLANE_SIZE;

				let a = this._objects[0].position.x - this._objects[1].position.x;
				let b = this._objects[0].position.y - this._objects[1].position.y;
				let widthPercent = Math.sqrt(a * a + b * b) / textureWidth;

				a = this._objects[0].position.x - this._objects[3].position.x;
				b = this._objects[0].position.y - this._objects[3].position.y;
				let heightPercent = Math.sqrt(a * a + b * b) / textureHeight;

				let percent = Math.max(widthPercent, heightPercent);

				this._focusedZ = 0.1 - percent * 0.1;

				// gsap.to(this._mainContainer.position, {duration: 0.5, x: centerX, y: -centerY});

				gsap.to(this._outerContainer.position, { duration: 0.5, z: this._focusedZ });

				//gsap.to(this._worldProperties, { duration: 0.5, cameraZ: this._focusedZ, onUpdate: this.updateCamera });
			}

			this.updateCropMask();
		}
	};

	private getTextureBounds = () => {
		let maxH = WindowManager.height - 170 - 170;
		this._screenPixelBounds = Resizer.getFitInside(this._textureWidth, this._textureHeight, WindowManager.width - 80, maxH);
		return Resizer.getFitInside(PLANE_SIZE * this._textureRatio, PLANE_SIZE, this._raybounds.bounds.width, this._raybounds.bounds.height);
	};

	private onDragStart = e => {
		e.object.material.map = this._blueTexture;
	};

	private onDrag = e => {
		let points = [];

		if (e.object._id === 0) {
			this._objectRefs['id-1'].position.y = e.object.position.y;
			this._objectRefs['id-3'].position.x = e.object.position.x;
		}

		if (e.object._id === 1) {
			this._objectRefs['id-0'].position.y = e.object.position.y;
			this._objectRefs['id-2'].position.x = e.object.position.x;
		}

		if (e.object._id === 2) {
			this._objectRefs['id-3'].position.y = e.object.position.y;
			this._objectRefs['id-1'].position.x = e.object.position.x;
		}

		if (e.object._id === 3) {
			this._objectRefs['id-2'].position.y = e.object.position.y;
			this._objectRefs['id-0'].position.x = e.object.position.x;
		}

		let l = this._objects.length;

		for (let i = 0; i < l; i++) {
			points.push(this._objects[i].position);
		}

		this.updateCropMask();

		this.drawLines(points);

		this.focusBoundingBox();

		this.updateBounds();
	};

	private updateCropMask = () => {
		let maskShape = new THREE.Shape();

		// let video = this._cameraFeed.video;
		// let ratio = this._textureWidth / this._textureHeight; //video.videoWidth / video.videoHeight;

		let bounds = this.getTextureBounds();
		let textureWidth = bounds.width;
		let textureHeight = bounds.height;

		// let textureWidth = PLANE_SIZE * ratio;
		// let textureHeight = PLANE_SIZE;

		let halfW = textureWidth * 0.5;
		let halfH = textureHeight * 0.5;

		let padding = 3; //0.01;

		maskShape.moveTo(-halfW - padding, -halfH - padding);
		maskShape.lineTo(halfW + padding, -halfH - padding);
		maskShape.lineTo(halfW + padding, halfH + padding);
		maskShape.lineTo(-halfW - padding, halfH + padding);

		let hole = new THREE.Shape();

		if (this._objects.length > 0) {
			hole.moveTo(this._objects[0].position.x, this._objects[0].position.y);

			let l = this._objects.length;

			for (let i = 0; i < l; i++) {
				if (i > 0) {
					hole.lineTo(this._objects[i].position.x, this._objects[i].position.y);
				}
			}
		} else {
			hole.moveTo(-halfW, -halfH);
			hole.lineTo(halfW, -halfH);
			hole.lineTo(halfW, halfH);
			hole.lineTo(-halfW, halfH);
		}

		maskShape.holes = [hole];
		this._cropMask.geometry = new THREE.ShapeGeometry(maskShape);
	};

	private onDragEnd = e => {
		e.object.material.map = this._greenTexture;

		this.focusBoundingBox();
	};

	public toggleCropDim = (state: boolean) => {
		gsap.to(this._cropMask.material, { duration: 0.3, opacity: state ? 0.75 : 0 });
	};

	public setCroppedImage = () => {
		let croppedImage = this.getCroppedImage();

		let texture = new THREE.Texture();
		texture.image = croppedImage;
		texture.needsUpdate = true;
		texture.wrapS = THREE.MirroredRepeatWrapping;
		texture.offset.x = -1 * texture.repeat.x;

		this.updateMesh(texture, croppedImage.width, croppedImage.height);
		this.updateBounds();
		this.resetPosition();
	};

	private updateMesh = (texture, width: number, height: number) => {
		this._textureWidth = width;
		this._textureHeight = height;
		this._textureRatio = width / height;

		// console.log('updateMesh: ' + this._textureWidth + ' / ' + this._textureHeight + ' ratio: ' + this._textureRatio);

		this.updateMeshSize();
		this.mesh.material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide, transparent: false });
	};

	private updateMeshSize = () => {
		// console.log('updateMeshSize');

		let bounds = this.getTextureBounds();
		this.mesh.geometry = new THREE.PlaneGeometry(bounds.width, bounds.height, 2, 2);
	};

	public toggleOrbitControls = (state: boolean) => {
		if (state === true && !this._3dViewEnabled) {
			this._controls.enabled = false;
		} else {
			this._controls.enabled = state;
		}
	};

	public resize = () => {
		super.resize();

		if (this._active) {
			// let scale = clamp(window.innerHeight / 900, 0, 1);
			// this._outerContainer.scale.set(scale, scale, 1);
			// this._outerContainer.position.y = (1 - scale) * 0.001 * -1;

			this.renderer.setSize(window.innerWidth, window.innerHeight);

			this._camera.fov = window.innerHeight / window.screen.height;
			this._camera.aspect = window.innerWidth / window.innerHeight;
			this._camera.updateProjectionMatrix();

			this._handJoints.resize();

			this._raybounds.update(WindowManager.height - 170, 170);

			if (this._boundingBoxActive && this._currentBoundingBox) {
				this.updateBoundingBox();
			}

			this.updateDragRestrictions();

			this.updateMeshSize();

			this.focusBoundingBox();
			this.updateBounds();
		}
	};

	private updateBoundingBox = () => {
		let boundingBox = this._currentBoundingBox;
		let video = this._cameraFeed.video;
		let bounds = this.getTextureBounds();
		let textureWidth = bounds.width;
		let textureHeight = bounds.height;

		let points = [
			// Top Left
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.topLeft[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.topLeft[1] / video.videoHeight),
				-0.01
			),
			// Top Right
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.bottomRight[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.topLeft[1] / video.videoHeight),
				-0.01
			),
			// Bottom Right
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.bottomRight[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.bottomRight[1] / video.videoHeight),
				-0.01
			),
			// Bottom Left
			new THREE.Vector3(
				-(textureWidth * 0.5) + textureWidth * (boundingBox.topLeft[0] / video.videoWidth),
				textureHeight * 0.5 - textureHeight * (boundingBox.bottomRight[1] / video.videoHeight),
				-0.01
			)
		];

		let l = points.length;

		this.normalizePoints(points);

		for (let i = 0; i < l; i++) {
			let dragHandle = this._objectRefs['id-' + i];
			dragHandle.position.x = points[i].x;
			dragHandle.position.y = points[i].y;
		}

		this.drawLines(points);
	};

	private animate = () => {
		requestAnimationFrame(this.animate);

		this._controls.update();

		this.renderer.render(this.scene, this._camera);
		this._handJoints.update(this.scene, this._camera);

		WorldPane.getInstance().refresh();
	};

	public getCroppedImage = (): HTMLCanvasElement => {
		let l = this._objects.length;
		let video = this._cameraFeed.video;
		// let ratio = video.videoWidth / video.videoHeight;

		let bounds = this.getTextureBounds();
		let textureWidth = bounds.width; //PLANE_SIZE * ratio;
		let textureHeight = bounds.height; //PLANE_SIZE;

		let xMin = 9999;
		let yMin = 9999;

		let xMax = 0;
		let yMax = 0;

		for (let i = 0; i < l; i++) {
			let pos = this._objects[i].position;

			let x = -(textureWidth * 0.5) + pos.x;
			let y = textureHeight * 0.5 + pos.y;

			let xPos = ((x * -1) / textureWidth) * video.videoWidth;
			let yPos = video.videoHeight - (y / textureHeight) * video.videoHeight;

			xMin = Math.min(xMin, xPos);
			yMin = Math.min(yMin, yPos);

			xMax = Math.max(xMax, xPos);
			yMax = Math.max(yMax, yPos);
		}

		// NOTE: need to flip the image horizontally before drawing, because the image is flipped in the 3d view
		let flippedImage = ImageUtils.flip(Globals.currentSnapshot, true, false, video.videoWidth, video.videoHeight);

		//return ImageUtils.crop(Globals.currentSnapshot, xMin, yMin, xMax - xMin, yMax - yMin);
		return ImageUtils.crop(flippedImage, xMin, yMin, xMax - xMin, yMax - yMin);
	};

	out() {
		this._cameraFeed.stopCamera();
		gsap.to(this._closeButton, { x: 300, ease: 'power1.in', duration: 0.3 });
		gsap.to(this.element, 0.5, { delay: 0.3, opacity: 0, onComplete: this.outComplete, overwrite: true });
	}

	kill() {
		Globals.HAND_SCENE.switchToMainScene();
		super.kill();
		this._mediaPipeClient.onResultSignal.remove(this.onMediaPipeResults);
	}

	get loader() {
		return this._loaderContainer;
	}
}
